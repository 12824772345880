import React, { FC, CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';

export interface SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
  src: string;
  style?: CSSProperties;
}

const SvgIcon: FC<SvgIconProps> = ({ width, height, fill, src, style }: SvgIconProps) => {
  return (
    <ReactSVG
      src={src}
      style={{ height: height, width: width, ...style }}
      //sx={{ margin: 4 }}
      beforeInjection={(svg) => {
        if (fill) {
          svg.setAttribute('fill', fill);
        }
      }}
    />
  );
};

export default SvgIcon;
