import { CLIENT_ERROR, CLIENT_CONNECTION_ERRORS } from './constants';
type Error = {
  key?: string;
  message?: string;
};

// Return formatted error object based on API response
export const composeError = (response: any) => {
  const error: Error = {};
  if (response.status === 401) {
    error.key = 'unauthorized';
    error.message = 'loginerror';
  }
  if (response.status === 429) {
    error.key = 'too_many_requests';
    error.message = 'too_many_requests_error_message';
  }
  if (response.problem === CLIENT_ERROR) {
    error.key = 'client_error';
    error.message = 'general_error_message';
  } else if (CLIENT_CONNECTION_ERRORS.includes(response.problem)) {
    error.key = 'network_error';
    error.message = 'general_error_message';
  } else {
    error.key = 'server_error';
    error.message = 'general_error_message';
  }
  return error;
};
