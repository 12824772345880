export const AUTH_TOKEN = 'AUTH_TOKEN';
export const USER_LOCAL = 'USER_LOCAL';

export function readStorage(key: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    const item = window.localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
}

export function writeStorage(key: string, value: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export function deleteStorage(key: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.removeItem(key);
  }
}
