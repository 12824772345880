import { types, Instance, flow } from 'mobx-state-tree';
import { getSingleContentPageByName } from '../services/api';
import { Tag, Category, Role } from '../types';

const ContentPageModel = types.model('ContentPageModel', {
  id: types.number,
  title: types.string,
  text: types.string,
  priorityIndex: types.number,
  image: types.maybeNull(types.string),
  categories: types.maybeNull(types.array(types.number)),
  tags: types.maybeNull(types.array(types.number)),
});

export type ContentPageModelType = Instance<typeof ContentPageModel>;

export const ContentPageStore = types
  .model({
    selectedContent: types.maybeNull(ContentPageModel),
  })
  .actions((self) => {
    const getContentPageByName = flow(function* (name: string) {
      try {
        const response = yield getSingleContentPageByName(name);
        if (response.data) {
          self.selectedContent = {
            ...response.data,
            categories: response.data?.categories?.map((data: Category) => data.id),
            tags: response?.data?.tags.map((data: Tag) => data.id),
            userRole: response.data?.userLevels?.map((data: Role) => data.id),
          };
        }
      } catch (error) {
        console.error(error);
      }
    });
    return {
      getContentPageByName,
    };
  });
