import { types, Instance, flow } from 'mobx-state-tree';
import {
  createSubscription,
  getSubscriptionProducts,
  getUserSubscriptions,
  cancelUserSubscription,
  confirmUserSubscription,
  getAllSubscriptionTypes,
  activateSubscriptionCode,
  inviteSubscriptionFamilyMember,
  getSubscriptionFamilyMembers,
  getAllGiftProducts,
  createStripePayment,
  confirmStripePayment,
} from '../services/api';
import { PaymentCreate, SubscriptionCancel, SubscriptionConfirm, SubscriptionCreate } from '../types';
import { withRootStore } from './withRootStore';
import { NotificationType } from './NotificationStore';

const SubscriptionModel = types.model('SubscriptionModel', {
  id: types.string,
  type: types.number,
  status: types.string,
  subscriptionTypeId: types.maybeNull(types.number), // 1 -> Stipe, 2 -> Subscription Code, 3... -> types defined in subscription type entity
  subscriptionCode: types.optional(types.string, ''),
  currentPeriodEnd: types.maybeNull(types.Date),
  currentPeriodStart: types.Date,
  interval: types.string,
  amount: types.number,
  subscriptionId: types.optional(types.string, ''),
  priceId: types.optional(types.string, ''),
  productId: types.optional(types.string, ''),
  ownerId: types.maybeNull(types.number), // perhe tilauksen tai paritilauksen omistaja id
  paymentType: types.number,
  ending: types.optional(types.boolean, false),
  //parent: types.maybeNull(SubscriptionModel),
  //parentSubscriptions:  types.optional(types.array(SubscriptionModel), []),
});

const SubscriptionCreateModel = types.model('SubscriptionCreateModel', {
  subscriptionId: types.string,
  clientSecret: types.string,
  priceId: types.string,
  supportCode: types.optional(types.string, ''),
});

const PaymentCreateModel = types.model('PaymentCreateModel', {
  paymentIntentId: types.string,
  clientSecret: types.string,
  priceId: types.string,
  subscriptionCodeId: types.number,
});

const ProductModel = types.model('ProductModel', {
  id: types.string,
  name: types.string,
  description: types.maybeNull(types.string),
  price_month: types.number,
  price_month_id: types.string,
  price_year: types.number,
  price_year_id: types.string,
  active: types.boolean,
});

const GiftProductModel = types.model('GiftProductModel', {
  id: types.string,
  product_id: types.string,
  name: types.string,
  description: types.maybeNull(types.string),
  price: types.number,
  price_id: types.string,
  active: types.boolean,
});

const SubscriptionTypeModel = types.model('SubscriptionTypeModel', {
  id: types.number,
  name: types.string,
  type: types.number,
  description: types.maybeNull(types.string),
  price: types.number,
  priceYear: types.number,
  active: types.boolean,
});

const FamilyMemberModel = types.model('FamilyMemberModel', {
  id: types.number,
  email: types.string,
  active: types.boolean,
  invited: types.boolean,
});

export type SubscriptionModelType = Instance<typeof SubscriptionModel>;
export type ProductModelType = Instance<typeof ProductModel>;
export type GiftProductModelType = Instance<typeof GiftProductModel>;
export type FamilyMemberType = Instance<typeof FamilyMemberModel>;

export const SubscriptionStore = types
  .model({
    createdSubscription: types.maybeNull(SubscriptionCreateModel),
    createdPayment: types.maybeNull(PaymentCreateModel),
    products: types.optional(types.array(ProductModel), []),
    giftProducts: types.optional(types.array(GiftProductModel), []),
    subscriptions: types.optional(types.array(SubscriptionModel), []),
    subscriptionTypes: types.optional(types.array(SubscriptionTypeModel), []),
    familyMembers: types.optional(types.array(FamilyMemberModel), []),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getProducts = flow(function* () {
      try {
        const result = yield getSubscriptionProducts();

        self.products = result.data.map((product: ProductModelType) => ({
          ...product,
        }));
        self.products.sort((a, b) => {
          if (!a || !b) return 0;

          if (a.name == 'Perustilaus') return -1;
          if (b.name == 'Perustilaus') return 1;

          if (a.name == 'Perhetilaus') return 1;
          if (b.name == 'Perhetilaus') return -1;
          return 0;
        });
      } catch (error) {
        console.error(error);
      }
    });

    const getGiftProducts = flow(function* () {
      try {
        const result = yield getAllGiftProducts();

        const array = result.data.map((giftProduct: GiftProductModelType) => ({
          ...giftProduct,
        }));

        array.sort((a: any, b: any) => a.price - b.price);

        self.giftProducts = array;
      } catch (error) {
        console.error(error);
      }
    });

    const getSubscriptions = flow(function* () {
      try {
        const result = yield getUserSubscriptions();

        self.subscriptions = result.data?.map((sub: SubscriptionModelType) => {
          return {
            id: sub.id,
            type: sub.type,
            status: sub.status,
            subscriptionTypeId: sub.subscriptionTypeId,
            subscriptionCode: sub.subscriptionCode ?? '',
            currentPeriodEnd: sub.currentPeriodEnd != null ? new Date(sub.currentPeriodEnd) : null,
            currentPeriodStart: new Date(sub.currentPeriodStart),
            interval: sub.interval,
            amount: sub.amount,
            subscriptionId: sub.subscriptionId,
            priceId: sub.priceId,
            productId: sub.productId,
            ownerId: sub.ownerId,
            paymentType: sub.paymentType,
            ending: sub.ending,
            //parent: types.maybeNull(SubscriptionModel),
            //parentSubscriptions:  types.optional(types.array(SubscriptionModel), []),
          };
        });
      } catch (error) {
        console.error(error);
      }
    });

    const getSubscription = flow(function* (subCreate: SubscriptionCreate) {
      try {
        const result = yield createSubscription(subCreate);

        self.createdSubscription = { ...result.data };
      } catch (error) {
        console.error(error);
      }
    });

    const cancelSubscription = flow(function* (subCancel: SubscriptionCancel) {
      try {
        const result = yield cancelUserSubscription(subCancel);
        self.rootStore.notificationStore.createNotification({
          message: 'Tilauksesi on peruttu',
          notificationType: NotificationType.Success,
        });
      } catch (error: any) {
        console.error(error);

        let message = '';
        if (error.response.data.message == 'not_owner') {
          message = 'Virhe tilauksen perumisessa';
        }
        if (error.response.data.message == 'family_member') {
          message = 'Vain tilauksen omistaja voi perua tilauksen';
        }

        self.rootStore.notificationStore.createNotification({
          message: message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const confirmSubscription = flow(function* (subscriptionConfirm: SubscriptionConfirm) {
      try {
        const result = yield confirmUserSubscription(subscriptionConfirm);
      } catch (error) {
        console.error(error);
      }
    });

    const getSubscriptionTypes = flow(function* () {
      try {
        const result = yield getAllSubscriptionTypes();

        self.subscriptionTypes = result.data.map((subType: SubscriptionModelType) => ({
          ...subType,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const activateCode = flow(function* (code: string) {
      try {
        const result = yield activateSubscriptionCode(code);

        self.rootStore.notificationStore.createNotification({
          message: 'Tilauskoodi aktivoitu tilaukseksi',
          notificationType: NotificationType.Success,
        });

        getSubscriptions();
      } catch (error: any) {
        if (error.response.status == 404) {
          self.rootStore.notificationStore.createNotification({
            message: 'Tilauskoodia ei löytynyt',
            notificationType: NotificationType.Error,
          });
        } else if (error.response.status == 400) {
          self.rootStore.notificationStore.createNotification({
            message: 'Tilauskoodi on jo käytetty',
            notificationType: NotificationType.Error,
          });
        } else {
          self.rootStore.notificationStore.createNotification({
            message: error.message,
            notificationType: NotificationType.Error,
          });
        }
        console.error(error);
      }
    });

    const getFamilyMembers = flow(function* () {
      try {
        const result = yield getSubscriptionFamilyMembers();

        self.familyMembers = result.data.map((member: FamilyMemberType) => ({
          ...member,
        }));
      } catch (error: any) {
        console.error(error);
      }
    });

    const inviteFamilyMember = flow(function* (email: string) {
      try {
        const result = yield inviteSubscriptionFamilyMember(email);

        self.rootStore.notificationStore.createNotification({
          message: 'Perheenjäsen kutsuttu',
          notificationType: NotificationType.Success,
        });
      } catch (error: any) {
        self.rootStore.notificationStore.createNotification({
          message: 'Perheenjäsenen kutsu epäonnistui',
          notificationType: NotificationType.Error,
        });
        console.error(error);
      }
    });

    const createGiftPayment = flow(function* (paymentCreate: PaymentCreate) {
      try {
        const result = yield createStripePayment(paymentCreate);

        self.createdPayment = { ...result.data };
      } catch (error) {
        console.error(error);
      }
    });

    const confirmGiftPayment = flow(function* (subscriptionCodeId: number, email: string) {
      try {
        const result = yield confirmStripePayment(subscriptionCodeId, email);
      } catch (error) {
        console.error(error);
      }
    });

    return {
      getProducts,
      getGiftProducts,
      getSubscription,
      getSubscriptions,
      cancelSubscription,
      confirmSubscription,
      getSubscriptionTypes,
      activateCode,
      inviteFamilyMember,
      getFamilyMembers,
      createGiftPayment,
      confirmGiftPayment,
    };
  });
