import { types, flow } from 'mobx-state-tree';
import { getAllFavorites, storeSingleFavorite, removeSingleFavorite } from '../services/api/favoriteApi';
import { Favorite } from '../types/Favorite';
import { composeError } from '../utils/transforms';

const FavoriteModel = types.model('FavoriteModel', {
  id: types.optional(types.number, -1),
  userId: types.number,
  audiobookId: types.maybeNull(types.number),
  albumId: types.maybeNull(types.number),
  podcastId: types.maybeNull(types.number),
  episodeId: types.maybeNull(types.number),
  externalEpisodeId: types.maybeNull(types.number),
  onlineMagazineId: types.maybeNull(types.number),
  programId: types.maybeNull(types.number),
  videoId: types.maybeNull(types.number),
  articleId: types.maybeNull(types.number),
});

export const FavoriteStore = types
  .model({
    favorites: types.optional(types.array(FavoriteModel), []),
  })
  .actions((self) => {
    const getFavorites = flow(function* () {
      try {
        const response = yield getAllFavorites();
        self.favorites = response.map((favorite: Favorite) => ({
          ...favorite,
        }));
      } catch (err) {
        const error = composeError(err);
        console.error(err);
        console.error(error);
      }
    });

    const storeArticleFavorite = flow(function* (articleId: number) {
      try {
        const favorite: Favorite = {
          articleId: articleId,
        };

        yield storeSingleFavorite(favorite);
        yield getFavorites();
      } catch (err) {
        const error = composeError(err);
        console.error(err);
        console.error(error);
      }
    });

    const removeArticleFavorite = flow(function* (articleId: number) {
      try {
        const favorite: Favorite = {
          articleId: articleId,
        };

        yield removeSingleFavorite(favorite);
        yield getFavorites();
      } catch (err) {
        const error = composeError(err);
        console.error(err);
        console.error(error);
      }
    });

    return {
      getFavorites,
      storeArticleFavorite,
      removeArticleFavorite,
    };
  });
