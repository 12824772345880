import { useContext, createContext } from 'react';
import { types, Instance } from 'mobx-state-tree';
//import makeInspectable from 'mobx-devtools-mst'

import { AppStore } from './AppStore';
import { CategoryStore } from './CategoryStore';
import { MediaStore } from './MediaStore';
import { UserStore } from './UserStore';
import { ContentPageStore } from './ContentPageStore';
import { NotificationStore } from './NotificationStore';
import { ArticleStore } from './ArticleStore';
import { MagazineStore } from './MagazineStore';
import { AudiobookStore } from './AudiobookStore';
import { SubscriptionStore } from './SubscriptionStore';
import { AlbumStore } from './AlbumStore';
import { PodcastStore } from './PodcastStore';
import { OnlineMagazineStore } from './OnlineMagazineStore';
import { FavoriteStore } from './FavoriteStore';

const RootModel = types.model('RootStore', {
  app: AppStore,
  categoryStore: CategoryStore,
  mediaStore: MediaStore,
  userStore: UserStore,
  contentPageStore: ContentPageStore,
  notificationStore: NotificationStore,
  articleStore: ArticleStore,
  magazineStore: MagazineStore,
  audiobookStore: AudiobookStore,
  subscriptionStore: SubscriptionStore,
  albumStore: AlbumStore,
  podcastStore: PodcastStore,
  onlineMagazineStore: OnlineMagazineStore,
  favoriteStore: FavoriteStore,
});

export const rootStore = RootModel.create({
  app: {},
  categoryStore: {},
  mediaStore: {},
  userStore: {},
  contentPageStore: {},
  notificationStore: {},
  articleStore: {},
  magazineStore: {},
  audiobookStore: {},
  subscriptionStore: {},
  albumStore: {},
  podcastStore: {},
  onlineMagazineStore: {},
  favoriteStore: {},
});

//makeInspectable(rootStore)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootInstance extends Instance<typeof RootModel> {}
const RootStoreContext = createContext<null | RootInstance>(null);

export const StoreProvider = RootStoreContext.Provider;
export function useMst() {
  const store = useContext(RootStoreContext);

  if (!store) {
    throw new Error('No context for store');
  }

  return store;
}
