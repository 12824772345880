export const breakpoints = {
  xs: 360, // Small mobile
  sm: 380, // Mobile
  md: 768, // Tablet
  lg: 1024,
  xl: 1280,
  xxl: 1366, // Laptop
  xxxl: 1920, //Desktop
  xxxxl: 2560, // Large Desktop
  xxxxxl: 3840, // 4K
};

export const fonts = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  arial: 'Arial, sans-serif',
};
