export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  darkSilver: '#707070',
  silver: '#F0F0F0',
  lightSilver: '#F5F5F5',
  darkerSilver: '#D8D8D8',
  darkGreen: '#3F6F0F',
  darkGray: '#3E3E3E',
  green: '#7EBB42',
  primaryGreen: '#6B8650',
  primaryPink: '#FF187B',
  lightGray: '#d5d5d5',
  eclipse: '#3e3e3e',
  boulder: '#777777',
  brightGreen: '#7ebb42',
  chaletGreen: '#4d5b39',
  menuGreen: '#576840',
  red: '#cc0000',
  verdigrisGreen: '#4B6233',
};
