import { graphql, useStaticQuery } from 'gatsby';

type ReturnValue = {
  site: {
    siteMetadata: {
      title: string;
      keywords: string[];
      author: string;
      description: string;
      image: string;
      siteUrl: string;
    };
  };
};

export const useSiteMetadata = () => {
  const data = useStaticQuery<ReturnValue>(graphql`
    query {
      site {
        siteMetadata {
          title
          keywords
          author
          description
          image
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
