import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMst } from '../../models/RootStore';
import { breakpoints } from '../../theme/variables';
import { colors } from '../../theme/colors';
import { Button, Typography, Link, Grid, IconButton, Container } from '@mui/material';
import { navigate } from 'gatsby';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';
import GooglePlayIcon from '../../resources/images/google-play-badge.png';
import AppStoreIcon from '../../resources/images/AppStoreDownload.svg';
import SvgIcon from '../Icons/SvgIcon';

const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lg });
  return isDesktop ? children : null;
};

const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.lg });
  return isMobile ? children : null;
};

export const PageFooter: FC = observer(() => {
  const { t } = useTranslation();
  const {
    app: { appItem },
  } = useMst();

  const sortedLinks = appItem?.footerLinks.slice().sort((a, b) => a.placement - b.placement);

  const renderSome = () => {
    return (
      <Grid container>
        <Grid>
          <Typography style={{ color: colors.white, marginBottom: 20, fontSize: 14 }}>{`${t(
            'app.followus'
          )}:`}</Typography>
        </Grid>
        <Grid container>
          <Grid item>
            <IconButton style={{ color: colors.white }} onClick={() => navigate('https://www.facebook.com/Aikamedia/')}>
              <FacebookIcon />
            </IconButton>
            <IconButton
              style={{ color: colors.white }}
              onClick={() => navigate('https://www.instagram.com/amaikamedia/')}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              style={{ color: colors.white, width: 40 }}
              onClick={() => navigate('https://www.youtube.com/@aikamedia8038/')}
            >
              <YoutubeIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLinks = () => {
    return (
      <Grid container item xs={8} spacing={1}>
        <Grid item>
          <Typography
            style={{ color: colors.white, marginBottom: 20, fontSize: 14 }}
          >{`${appItem?.appName}:`}</Typography>
        </Grid>
        {sortedLinks?.map((link, index) => (
          <Grid key={index} item xs={12}>
            <Link
              onClick={() => navigate(link.url)}
              color={colors.white}
              style={{ textDecoration: 'none', marginBottom: 8, cursor: 'pointer', fontSize: 14 }}
            >
              <Typography variant="body1" style={{ color: colors.white, fontSize: 12 }}>
                {link.text}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderInput = () => {
    return (
      <Grid container>
        <Grid item xs={12} textAlign={'center'}>
          <Typography style={{ color: colors.white, marginBottom: 20, fontSize: 14 }}>{`${t(
            'newsletter.subscribe_newsletter'
          )}:`}</Typography>
        </Grid>
        <Grid item xs={12} textAlign={'center'}>
          <Button
            variant="outlined"
            onClick={() => navigate('/uutiskirje')}
            style={{
              borderRadius: 30,
              borderWidth: 2,
              borderColor: colors.primaryPink,
              paddingLeft: 40,
              paddingRight: 40,
              textTransform: 'uppercase',
            }}
          >
            {t('newsletter.subscribe')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  const renderApps = () => {
    return (
      <Grid container>
        <Grid item xs={12} textAlign={'center'}>
          <Typography style={{ color: colors.white, marginBottom: 20, fontSize: 14 }}>{`${t(
            'app.appsTitle'
          )}:`}</Typography>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <Link href="https://play.google.com/store/apps/details?id=fi.aikamedia.ajassa_mobile" target="_blank">
            <img src={GooglePlayIcon} style={{ width: 120 }} />
          </Link>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'} mt={1}>
          <Link href="https://apps.apple.com/app/ajassa-sovellus/id6448910556" target="_blank">
            <SvgIcon src={AppStoreIcon} height={30} width={120} />
          </Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container style={{ backgroundColor: colors.black, margin: 0, padding: 40, minWidth: '100%' }}>
      <Desktop>
        <Grid container justifyContent={'space-evenly'}>
          <Grid item xs={1} />
          <Grid item xs={2}>
            {renderSome()}
          </Grid>
          <Grid item xs={3}>
            {renderInput()}
          </Grid>
          <Grid item xs={3}>
            {renderApps()}
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            {renderLinks()}
          </Grid>
        </Grid>
      </Desktop>
      <Mobile>
        <Grid container>
          <Grid item xs={12}>
            {renderSome()}
          </Grid>
          <Grid item xs={12} mt={4}>
            {renderLinks()}
          </Grid>
          <Grid item xs={12} mt={4}>
            {renderInput()}
          </Grid>
        </Grid>
      </Mobile>
    </Container>
  );
});
