import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../models/RootStore';
import { breakpoints } from '../../theme/variables';
import { Button, Container, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ajassa_logo from '../../resources/images/ajassa-logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AjassaLogo from '../../resources/images/ajassa-lehti-logo-white.png';
import { getCommunities } from '../../services/api/communityApi';
import { Community } from '../../types';
import OnlineMagazineMenu from './OnlineMagazineMenu';

export const OnlineMagazineHeader: FC = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { token },
  } = useMst();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lg });
  const isTablet = useMediaQuery({ minWidth: breakpoints.md });
  const isMobile = useMediaQuery({ maxWidth: breakpoints.lg });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [communities, setCommunities] = useState<Community[]>();

  useEffect(() => {
    const getCommunityList = async () => {
      const communityList = await getCommunities();
      setCommunities(communityList);
    };

    getCommunityList();
  }, []);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid className="online_magazine_header">
      <Container maxWidth={isMobile ? 'sm' : 'md'}>
        <Grid container item xs={12} alignContent={'center'} textAlign={'center'}>
          <Grid container item xs={3}>
            <Grid item alignContent="center" mr={1}>
              <img src={ajassa_logo} alt="logo" style={{ maxHeight: 20 }} />
            </Grid>
            <Grid item onClick={() => navigate('/')} style={{ cursor: 'pointer' }} alignSelf="center">
              <Typography color="white" fontSize={12}>
                {t('magazine.backToMainPage')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item height={72} alignContent={'center'}>
              <img src={AjassaLogo} alt="Ajassa-lehti" height={40} />
            </Grid>
          </Grid>
          <Grid item xs={5} alignContent={'center'} justifyContent={'flex-end'}>
            <Grid container item justifyContent="flex-end">
              {isTablet && !token && (
                <Grid item alignSelf={'flex-end'}>
                  <Button
                    variant="text"
                    onClick={() => {
                      navigate('/kirjaudu');
                      setAnchorEl(null);
                    }}
                  >
                    <Typography fontSize={12} color="white">
                      {t('login.login')}
                    </Typography>
                  </Button>
                </Grid>
              )}

              <Grid item>
                <IconButton onClick={handleClick} size="small">
                  {anchorEl ? <CloseIcon htmlColor="white" /> : <MenuIcon htmlColor="white" />}
                </IconButton>
                <OnlineMagazineMenu
                  open={open}
                  anchor={anchorEl}
                  handleClose={() => setAnchorEl(null)}
                  communities={communities}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
});
