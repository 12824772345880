import { OnlineMagazineResponse, StoryTip } from '../../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  onlineMagazines: {
    root: '/onlineMagazines/published',
    id: '/onlineMagazines/published/{id}',
    storyTip: '/onlineMagazines/storyTip',
  },
});

// OnlineMagazine
export const getOnlineMagazines = async (): Promise<OnlineMagazineResponse[]> => {
  const retval = await api.get(API_PATHS.onlineMagazines.root, {});
  return retval.data;
};

export const getOnlineMagazine = async (id: string): Promise<OnlineMagazineResponse> => {
  const retval = await api.get(API_PATHS.onlineMagazines.id.replace('{id}', id), {});
  return retval.data;
};

export const sendStoryTip = async (storyTip: StoryTip): Promise<void> => {
  const retval = await api.post(API_PATHS.onlineMagazines.storyTip, storyTip);
  return retval.data;
};
