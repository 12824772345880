import React, { FC, MouseEventHandler } from 'react';
import { Button, SxProps, Theme, Typography } from '@mui/material';
import { colors } from '../../theme/colors';

interface AjassaButtonProps {
  title?: string | null;
  color?: 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'info' | 'warning';
  small?: boolean;
  tooltip?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  width?: number;
  sx?: SxProps<Theme>;
  textColor?: string;
  textWeight?: string;
}

const AjassaButton: FC<AjassaButtonProps> = ({
  title,
  onClick,
  color = 'primary',
  small,
  tooltip,
  variant = 'contained',
  disabled,
  type,
  width,
  sx,
  textColor,
  textWeight,
}: AjassaButtonProps) => {
  const doClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClick?.(event);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Button
      variant={variant}
      size={small === true ? 'small' : 'medium'}
      color={color}
      onClick={type ? undefined : doClick}
      disabled={disabled}
      title={tooltip}
      style={{ borderRadius: 35, width: width ?? 200 }}
      type={type}
      sx={sx}
    >
      <Typography style={{ fontWeight: textWeight ?? '500', fontSize: 16, color: textColor ?? colors.white }}>
        {title}
      </Typography>
    </Button>
  );
};

export default AjassaButton;
