import { types, Instance } from 'mobx-state-tree';

export enum NotificationType {
  Error = 'Error',
  Success = 'Success',
}

const NotificationModel = types.model('NotificationModel', {
  message: types.string,
  notificationType: types.optional(types.enumeration(Object.keys(NotificationType)), NotificationType.Success),
});

export type NotificationModelType = Instance<typeof NotificationModel>;

export const NotificationStore = types
  .model({
    notification: types.maybeNull(NotificationModel),
  })
  .actions((self) => {
    const createNotification = (notification: NotificationModelType | null) => {
      self.notification = notification;
    };

    return {
      createNotification,
    };
  });
