import React, { FC, MouseEvent, useState } from 'react';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../models/RootStore';
import { breakpoints } from '../../theme/variables';
import UserSvg from '../../resources/icons/user_filled.svg';
import { colors } from '../../theme/colors';
import SvgIcon from '../Icons/SvgIcon';
import { Button, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AjassaButton from '../Common/AjassaButton';
import { Login, Logout, Person, PersonAdd } from '@mui/icons-material';
import { OnlineMagazineHeader } from './OnlineMagazineHeader';

export const PageHeader: FC = observer(() => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lg });
  const isTablet = useMediaQuery({ minWidth: breakpoints.md });
  const isMobile = useMediaQuery({ minWidth: breakpoints.sm });
  const {
    app: { appItem, setMenuOpen },
    userStore: { token, logout },
  } = useMst();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO search (vaihe 2)
  const search = () => {
    /* not implemented */
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (url.includes('verkkolehti')) {
    return <OnlineMagazineHeader />;
  }

  return (
    <Grid
      container
      style={{ width: '100%', height: 72, overflow: 'hidden', backgroundColor: 'white' }}
      pl={2}
      pr={2}
      className="header"
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={2}>
          <img
            src={appItem?.logo ?? undefined}
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer', maxHeight: 40, marginTop: 20 }}
          />
        </Grid>
        <Grid item xs={7} alignSelf="center" textAlign="right" mt={2} style={{ height: 40 }}>
          {!token && isTablet && (
            <AjassaButton title={t('subscription.orderTitle')} width={150} onClick={() => navigate('/hinnasto')} />
          )}
          {!token && !isTablet && isMobile && (
            <AjassaButton title={t('product.order')} width={100} onClick={() => navigate('/hinnasto')} />
          )}
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end" alignSelf="center" mb={1} mt={2}>
          {isTablet && !token && (
            <Grid item alignSelf={'flex-end'}>
              <Button
                variant="text"
                onClick={() => {
                  navigate('/kirjaudu');
                  setMenuOpen(false);
                }}
              >
                <Typography variant="body2" color="secondary">
                  {t('login.login')}
                </Typography>
              </Button>
            </Grid>
          )}

          <Grid item>
            <IconButton onClick={handleClick} size="small">
              <SvgIcon src={UserSvg} width={21} height={21} fill={colors.black} />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: -10 }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              elevation={3}
            >
              {token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/tilaukset');
                  }}
                >
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  <Typography>{t('menu.my_subscriptions')}</Typography>{' '}
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/profiili');
                  }}
                >
                  <ListItemIcon>
                    <Person fontSize="small" />
                  </ListItemIcon>
                  <Typography>{t('menu.my_information')}</Typography>
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logout();
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  {t('menu.logout')}
                </MenuItem>
              )}

              {!token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/salasana-unohtunut');
                  }}
                >
                  {t('login.forgot_password')}
                </MenuItem>
              )}
              {!token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/rekisteroidy');
                  }}
                >
                  {t('menu.user_registration')}
                </MenuItem>
              )}
              {!token && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('/kirjaudu');
                  }}
                >
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  {t('menu.login')}
                </MenuItem>
              )}
            </Menu>
          </Grid>

          {/* <Grid item>
            <IconButton
              onClick={() => {
                token && setMenuOpen();
              }}
            >
              <SvgIcon src={MenuSvg} width={25} height={25} fill={colors.black} />
            </IconButton>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
});
