import { createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';

export type SupportedLocales = keyof typeof locales;

export const getThemeLocale = (): SupportedLocales => {
  // locale: AppLanguage oli parametrina

  const themeLocale: SupportedLocales = 'fiFI';

  // switch (locale) {
  //     case AppLanguage.Swedish:
  //         themeLocale = 'svSE';
  //         break;
  //     case AppLanguage.English:
  //         themeLocale = 'enUS';
  //         break;
  //     case AppLanguage.Finnish:
  //     default:
  //         break;
  // }

  return themeLocale;
};

export const getThemeColor = (
  color?: 'primary' | 'secondary' | 'default' | 'alert' | 'error' | 'warning' | 'success'
): string => {
  let htmlColor = '';

  switch (color) {
    case 'primary':
      htmlColor = '#FF187B';
      break;
    case 'secondary':
      htmlColor = '#000000';
      break;
    case 'warning':
      htmlColor = '#ff9800';
      break;
    case 'alert':
    case 'error':
      htmlColor = '#d32f2f';
      break;
    case 'success':
      htmlColor = '#2e7d32';
      break;
    default:
      htmlColor = '#000000';
      break;
  }

  return htmlColor;
};

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: getThemeColor('primary'),
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff',
    },
    secondary: {
      //light: '#0066ff',
      main: getThemeColor('secondary'),
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    warning: {
      main: getThemeColor('warning'),
      contrastText: '#ffffff',
    },
    error: {
      main: getThemeColor('error'),
      contrastText: '#ffffff',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
  },
  typography: {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'none',
    },
    h1: {
      color: getThemeColor('primary'),
      fontWeight: 300,
    },
    h2: {
      color: getThemeColor('primary'),
      fontWeight: 600,
      fontSize: 32,
    },
    h3: {
      color: getThemeColor('primary'),
      fontWeight: 300,
    },
    h4: {
      color: getThemeColor('primary'),
      fontWeight: 300,
    },
    h5: {
      color: getThemeColor('primary'),
      fontWeight: 600,
      fontSize: 16,
    },
    h6: {
      color: getThemeColor('primary'),
      fontWeight: 400,
    },
  },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        groupedText: {
          color: 'white !important',
          borderColor: 'white !important',
        },
        groupedTextHorizontal: {
          borderColor: 'white !important',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: 'white !important',
        },
        icon: {
          color: 'white !important',
        },
      },
    },
  },
});
