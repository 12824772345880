import { UserUpdateRequest } from '../../types';
import { api, API_PATHS } from './api';

export const fetchMe = async () => {
  return api.get(API_PATHS.user.me, {});
};

export const updateUser = async (userDetails: UserUpdateRequest) => {
  return api.put(API_PATHS.user.update, userDetails);
};

export const forgotPassword = async (email: string) => {
  return api.post(API_PATHS.user.forgotPwd, { email });
};

export const resetPassword = async (token: string, password: string) => {
  return api.post(API_PATHS.user.resetPwd, { token, password });
};

export const subscribeNewsletter = async (email: string, marketing: boolean) => {
  return api.post(API_PATHS.user.subscribeNewsletter, { email, marketing });
};
