/* eslint-disable no-plusplus */
import { types, Instance, flow } from 'mobx-state-tree';
import {
  getAllAudiobooks,
  getSingleAudiobook,
  fetchFilteredAudiobooks,
  fetchAudiobookMediaTypeId,
  getMediaToken,
} from '../services/api';
import { Chapter, AudiobookResponse } from '../types/Audiobook';
import { composeError } from '../utils/transforms';
import { MediaModel } from './MediaStore';
import { MediaFile, MediaFilterParams } from '../types';

export enum AudioPlayerState {
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
}

export const ChapterModel = types.model('ChapterModel', {
  id: types.optional(types.number, -1),
  name: types.optional(types.string, ''),
  src: types.optional(types.string, ''),
  chapterNumber: types.optional(types.number, -1),
  duration: types.optional(types.number, 0),
});

export const AudiobookModel = types.model('AudiobookModel', {
  id: types.optional(types.number, -1),
  name: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  image: types.optional(types.string, ''),
  chapters: types.optional(types.array(ChapterModel), []),
  author: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
  releaseDate: types.optional(types.string, ''),
});

export const TempAudiobookModel = types.model('TempAudiobookModel', {
  id: types.optional(types.number, -1),
  title: types.optional(types.string, ''),
  imageSrc: types.optional(types.string, ''),
  mediaSrc: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
  releaseDate: types.optional(types.string, ''),
});

export type AudiobookModel = Instance<typeof AudiobookModel>;
export type ChapterModel = Instance<typeof ChapterModel>;

export const AudiobookStore = types
  .model({
    audiobooks: types.optional(types.array(AudiobookModel), []),
    tempAudiobooks: types.optional(types.array(TempAudiobookModel), []),
    selectedAudiobook: types.optional(AudiobookModel, {}),
    selectedChapter: types.optional(ChapterModel, {}),
    filteredAudiobooks: types.optional(types.array(MediaModel), []),
    playerOpen: types.optional(types.boolean, false),
    currentChapter: types.optional(types.number, 0),
    playerState: types.optional(types.string, AudioPlayerState.PAUSE),
    audiobookMediaTypeId: types.optional(types.number, -1),
  })
  .actions((self) => {
    const fetchAudiobooks = flow(function* () {
      try {
        const response = yield getAllAudiobooks();
        const tempAudiobooks = response.data?.map((audiobook: AudiobookResponse) => {
          return {
            id: audiobook.id,
            title: audiobook.name,
            imageSrc: audiobook.image,
            mediaSrc: `/aanikirjat?audiobookId=${audiobook.id}`,
            description: audiobook.description,
            publisher: audiobook.publisher,
            releaseDate: audiobook.releaseDate,
          };
        });
        self.audiobooks = response.data;
        self.tempAudiobooks = tempAudiobooks;
        return tempAudiobooks;
      } catch (err) {
        const error = composeError(err);
        console.error(error);
      }
    });

    const getSelectedAudiobook = flow(function* (id: string) {
      try {
        const response = yield getSingleAudiobook(id);
        self.selectedAudiobook = {
          ...response.data,
        };
        return self.selectedAudiobook;
      } catch (err) {
        const error = composeError(err);
        console.error(error);
      }
    });

    const getFilteredAudiobooks = flow(function* (params: MediaFilterParams) {
      try {
        const response = yield fetchFilteredAudiobooks(params);
        const data = response.data.map((audiobook: MediaFile) => ({
          ...audiobook,
          releaseDate: audiobook.releaseDate,
          publisher: audiobook.publisher ?? '',
          author: audiobook.author ?? '',
        }));
        self.filteredAudiobooks = data;
        return data;
      } catch (err) {
        console.error(err);
      }
    });

    const setSelectedChapter = flow(function* (chapter: Chapter) {
      const result = yield getMediaToken(chapter.src);
      self.selectedChapter = { ...chapter, src: `${chapter.src}${result.data.token}` };
    });

    const clearSelectedAudiobook = () => {
      self.selectedAudiobook = {} as AudiobookModel;
      self.selectedChapter = {} as ChapterModel;
      self.playerOpen = false;
    };

    const setPlayerOpen = (value: boolean) => {
      self.playerOpen = value;
    };

    const setAudioPlayerState = (value: AudioPlayerState) => {
      if (value === AudioPlayerState.PLAY) {
        self.playerState = AudioPlayerState.PLAY;
      } else if (value === AudioPlayerState.PAUSE) {
        self.playerState = AudioPlayerState.PAUSE;
      }
    };

    const setCurrentChapter = (value: number | string) => {
      if (value === 'PREVIOUS') {
        self.currentChapter--;
      } else if (value === 'NEXT') {
        self.currentChapter++;
      } else if (isNumber(value)) {
        self.currentChapter = value as number;
      }
    };

    const isNumber = (value: number | string): boolean => {
      return typeof value === 'number' && isFinite(value);
    };

    const getAudiobookMediaType = flow(function* () {
      try {
        const response = yield fetchAudiobookMediaTypeId();
        self.audiobookMediaTypeId = response.data;
      } catch (err) {
        console.error(err);
      }
    });

    return {
      fetchAudiobooks,
      getSelectedAudiobook,
      clearSelectedAudiobook,
      setPlayerOpen,
      setSelectedChapter,
      setCurrentChapter,
      setAudioPlayerState,
      getFilteredAudiobooks,
      getAudiobookMediaType,
    };
  });
