import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { StyledLink } from './Common.styled';

interface LinkProps extends PropsWithChildren {
  className?: string;
  url: string;
  onClick?: () => void;
  state?: { [key: string]: string };
}

export const Link: React.FC<LinkProps> = observer((props) => {
  const { children, className, url, state, onClick } = props;
  return (
    <StyledLink to={url} className={className} onClick={onClick} state={state}>
      {children}
    </StyledLink>
  );
});
