import React from 'react';
import './layout.css';
import { HeadFC, graphql, useStaticQuery } from 'gatsby';
import { rootStore, StoreProvider } from '../models/RootStore';
import 'modern-normalize';
import '../theme/normalize';
import { LayoutRoot, LayoutMain } from '../components/Layout';
import { PageHeader } from '../components/PageHeader';
import { PageFooter } from '../components/PageFooter';
// import { Navigation } from '../components/Navigation';
// import { Menu } from '../components/Menu';
import { Notification } from '../components/Notification';
import { ThemeProvider, createTheme } from '@mui/material';
import { getThemeLocale, theme } from '../config/theme';
import * as MuiThemeLocales from '@mui/material/locale';
import AppStateProvider from '../context/appStateContext';
import { Seo } from '../components/Seo/Seo';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const IndexLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const data: StaticQueryProps = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  if (!data) return null;

  return (
    <AppStateProvider>
      <StoreProvider value={rootStore}>
        <ThemeProvider theme={createTheme(theme, MuiThemeLocales[getThemeLocale()])}>
          <LayoutRoot className="container">
            <PageHeader />
            {/* <Menu /> */}
            {/* <Navigation /> */}
            <LayoutMain>{children}</LayoutMain>
            <PageFooter />
            <Notification />
          </LayoutRoot>
        </ThemeProvider>
      </StoreProvider>
    </AppStateProvider>
  );
};

export default IndexLayout;

export const Head: HeadFC = () => <Seo />;
