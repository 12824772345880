import React from 'react';
import { StyledLayoutMain } from './Layout.styled';

interface LayoutMainProps {
  children: React.ReactNode;
  className?: string;
}

export const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => {
  return <StyledLayoutMain className={className}>{children}</StyledLayoutMain>;
};
