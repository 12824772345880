import axios from 'axios';
import apiErrorHandler from '../apiErrorHandler';
import { readStorage } from '../../utils/storage';
import {
  ArticleFilterParams,
  MediaFilterParams,
  MediaRating,
  PaymentCreate,
  SubscriptionCancel,
  SubscriptionConfirm,
  SubscriptionCreate,
} from '../../types';
import config from '../../config/config';

export const API_PATHS = Object.freeze({
  getAppItems: '/app-item',
  link: '/link',
  promotion: '/promotion',
  collectionPage: {
    id: '/collection/media/{pageId}',
    audiobook: '/collection/audiobook/{pageId}',
    newspaper: '/collection/newspaper/{pageId}',
    category: '/collection/page/{pageId}',
    all: '/collection',
  },
  media: {
    types: '/media',
    latest: '/media/latest',
    latestType: '/media/latest-type/{pageId}',
    typePerams: '/media/type-params/{mediaType}',
    filtered: '/media/filtered-media',
    recommendation: '/recommendation/user',
    generalRecomendation: '/recommendation',
    saveRating: '/media-rating',
  },
  auth: {
    register: '/auth/register',
    login: '/auth/userlogin',
    verifyEmail: '/auth/verify-email',
    mediatoken: '/auth/mediatoken',
    subscriptiontoken: '/auth/subscriptiontoken',
  },
  user: {
    logout: '/user/logout',
    me: '/user/me',
    update: '/user',
    forgotPwd: '/user/forgot-password',
    resetPwd: '/user/reset-password',
    subscribeNewsletter: '/user/subscribe-newsletter',
  },
  article: {
    id: '/articles/published/{pageId}',
    get: '/articles/published',
    filtered: '/articles/filtered',
  },
  audiobook: {
    id: '/audiobooks/published/{pageId}',
    get: '/audiobooks/published',
    filtered: '/audiobooks/filtered',
    mediaType: '/audiobooks/mediaTypes',
  },
  content: {
    name: '/content/name/{name}',
  },
  magazine: {
    get: '/newspaper/published',
    id: '/newspaper/published/{id}',
    filtered: '/newspaper/filtered',
  },
  subscription: {
    get: '/subscription',
    products: '/subscription/products',
    giftProducts: '/subscription/gift-products',
    create: '/subscription',
    giftPayment: '/subscription/gift-payment',
    cancel: '/subscription/cancel',
    confirm: '/subscription/confirm',
    types: '/subscription/types',
    activateCode: '/subscription/activate-code',
    familyMembers: '/subscription/family-members',
    inviteFamilyMember: '/subscription/invite-family-member',
    confirmGiftPayment: '/subscription/gift-confirm',
  },
  albums: {
    albums: '/v3/albums/published',
    id: '/v3/albums/published/{id}',
    albumBySongId: '/v3/albums/published/song/{id}',
  },
  podcasts: {
    podcasts: '/v3/podcasts/published',
    id: '/v3/podcasts/published/{id}',
    checkRssFeed: '/v3/podcasts/published/checkRssFeed',
    episodes: '/v3/podcasts/episodes/{id}',
  },
});

export const api = axios.create({
  baseURL: config.API_URL + config.API_VERSION,
  timeout: 20000,
});

// Errors
api.interceptors.response.use((response) => response, apiErrorHandler);

api.interceptors.request.use((config) => {
  const accessToken = readStorage('AUTH_TOKEN');
  if (accessToken !== null && config.headers) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

// App
export const getAppItems = () => {
  return api.get(API_PATHS.getAppItems);
};
export const getLinks = () => {
  return api.get(API_PATHS.link);
};
export const getPromotions = () => {
  return api.get(API_PATHS.promotion);
};

// Categories
export const getCollectionPageByName = (pageId: string) => {
  return api.get(API_PATHS.collectionPage.id.replace('{pageId}', pageId), {});
};

export const getAudiobookCollectionPage = (pageId: string) => {
  return api.get(API_PATHS.collectionPage.audiobook.replace('{pageId}', pageId), {});
};

export const getMagazineCollectionPage = (pageId: string) => {
  return api.get(API_PATHS.collectionPage.newspaper.replace('{pageId}', pageId), {});
};

export const getCollectionPageCategories = (pageId: string) => {
  return api.get(API_PATHS.collectionPage.category.replace('{pageId}', pageId), {});
};

export const getCollectionPages = async () => {
  return api.get(API_PATHS.collectionPage.all, {});
};

// Medias
export const fetchMediaTypes = () => {
  return api.get(API_PATHS.media.types, {});
};

export const getLatestMedias = () => {
  return api.get(API_PATHS.media.latest, {});
};
export const getLatestMediasByType = (pageId: string) => {
  return api.get(API_PATHS.media.latestType.replace('{pageId}', pageId), {});
};

export const fetchMediatypeParams = (mediaType: string) => {
  return api.get(API_PATHS.media.typePerams.replace('{mediaType}', mediaType), {});
};

export const fetchFilteredMedia = (params: MediaFilterParams) => {
  return api.get(API_PATHS.media.filtered, { params });
};

// Content
export const getSingleContentPageByName = (name: string) => {
  return api.get(API_PATHS.content.name.replace('{name}', name), {});
};

// Article
export const getAllArticles = () => {
  return api.get(API_PATHS.article.get, {});
};

export const getSingleArticlePage = (pageId: string) => {
  return api.get(API_PATHS.article.id.replace('{pageId}', pageId), {});
};

export const fetchFilteredArticles = (params: ArticleFilterParams) => {
  return api.get(API_PATHS.article.filtered, { params });
};

// Recommendation
export const getUserRecommendedMedia = () => {
  return api.get(API_PATHS.media.recommendation);
};
export const getGeneralRecommendation = () => {
  return api.get(API_PATHS.media.generalRecomendation);
};
export const saveRating = (rating: MediaRating) => {
  return api.post(API_PATHS.media.saveRating, rating);
};
export const getAllRatings = () => {
  return api.get(API_PATHS.media.saveRating, {});
};

// Magazine
export const fetchAllMagazines = async () => {
  return api.get(API_PATHS.magazine.get, {});
};
export const fetchMagazineById = async (id: string) => {
  return api.get(API_PATHS.magazine.id.replace('{id}', id), {});
};

export const fetchFiltredMagazines = (params: MediaFilterParams) => {
  return api.get(API_PATHS.magazine.filtered, { params });
};

// Article
export const getAllAudiobooks = async () => {
  return api.get(API_PATHS.audiobook.get, {});
};

// Audiobooks

export const getSingleAudiobook = async (pageId: string) => {
  return api.get(API_PATHS.audiobook.id.replace('{pageId}', pageId), {});
};

export const fetchFilteredAudiobooks = async (params: MediaFilterParams) => {
  return api.get(API_PATHS.audiobook.filtered, { params });
};

export const fetchAudiobookMediaTypeId = async () => {
  return api.get(API_PATHS.audiobook.mediaType, {});
};

// Mediatoken
export const getMediaToken = (resourceUrl: string) => {
  return api.post(API_PATHS.auth.mediatoken, { resourceUrl });
};

// Subscriptiontoken
export const getSubscriptiontoken = () => {
  return api.get(API_PATHS.auth.subscriptiontoken);
};

// Subscription
export const getSubscriptionProducts = () => {
  return api.get(API_PATHS.subscription.products);
};

export const getAllGiftProducts = () => {
  return api.get(API_PATHS.subscription.giftProducts);
};

export const getUserSubscriptions = () => {
  return api.get(API_PATHS.subscription.get);
};

export const createSubscription = (subCreate: SubscriptionCreate) => {
  return api.post(API_PATHS.subscription.create, subCreate);
};

export const cancelUserSubscription = (subCancel: SubscriptionCancel) => {
  return api.post(API_PATHS.subscription.cancel, subCancel);
};

export const confirmUserSubscription = (subscriptionConfirm: SubscriptionConfirm) => {
  return api.post(API_PATHS.subscription.confirm, subscriptionConfirm);
};

export const getAllSubscriptionTypes = () => {
  return api.get(API_PATHS.subscription.types);
};

export const activateSubscriptionCode = (code: string) => {
  return api.post(API_PATHS.subscription.activateCode, { code });
};

export const getSubscriptionFamilyMembers = () => {
  return api.get(API_PATHS.subscription.familyMembers);
};

export const inviteSubscriptionFamilyMember = (email: string) => {
  return api.post(API_PATHS.subscription.inviteFamilyMember, { email });
};

export const createStripePayment = (paymentCreate: PaymentCreate) => {
  return api.post(API_PATHS.subscription.giftPayment, paymentCreate);
};

export const confirmStripePayment = (subscriptionCodeId: number, email: string) => {
  return api.post(API_PATHS.subscription.confirmGiftPayment, { subscriptionCodeId, email });
};

// Album
export const getAlbums = () => api.get(API_PATHS.albums.albums, {});
export const getSingleAlbum = (id: string) => api.get(API_PATHS.albums.id.replace('{id}', id), {});
export const getSingleAlbumBySongId = (id: string) => api.get(API_PATHS.albums.albumBySongId.replace('{id}', id), {});

//Podcast
export const getPodcasts = () => api.get(API_PATHS.podcasts.podcasts, {});
export const getSinglePodcast = (id: string) => api.get(API_PATHS.podcasts.id.replace('{id}', id), {});

export const checkRssFeed = (url: string) => api.put(API_PATHS.podcasts.checkRssFeed, { feedUrl: url });

export const getSingleEpisode = (id: string) => api.get(API_PATHS.podcasts.episodes.replace('{id}', id), {});
