import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from '../../theme/colors';
import { fonts, breakpoints } from '../../theme/variables';

export const Text = styled.p`
  font: ${fonts.arial};
  color: ${colors.black};
  margin: 0px;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  font: ${fonts.arial};
  cursor: pointer;
`;
export const ImageHeaderContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
`;
export const ImageHeaderText = styled.p`
  background-color: ${colors.darkGray};
  font: ${fonts.arial};
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  align-self: center;
  width: auto;
  max-width: 60%;
  margin: 0px;
  padding: 5px;
  margin-left: 10px;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    font-size: 15px;
  }
`;
export const Favorite = styled.button.attrs((props: { backgroundColor: string | undefined }) => props)`
  border: none;
  background: none;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
`;
export const Label = styled.label`
  width: 100%;
  font: ${fonts.arial};
  color: ${colors.eclipse};
  font-size: 15px;
  margin: 5px 0px;
  align-self: center;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    width: 80%;
    align-self: flex-start;
  }

  @media ${`(min-width: ${breakpoints.lg}px)`} {
    width: 40%;
  }
`;
export const ErrorText = styled.p`
  width: 100%;
  font: ${fonts.arial};
  color: ${colors.red};
  font-size: 15px;
  align-self: center;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    width: 80%;
    align-self: flex-start;
  }

  @media ${`(min-width: ${breakpoints.lg}px)`} {
    width: 40%;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;
export const Input = styled.input`
  width: 100%;
  padding: 15px 10px;
  border: 1px solid ${colors.darkSilver};
  align-self: center;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    width: 80%;
    align-self: flex-start;
  }
  @media ${`(min-width: ${breakpoints.lg}px)`} {
    width: 50%;
  }

  @media ${`(min-width: ${breakpoints.xl}px)`} {
    width: 40%;
  }
`;
export const CheckboxWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0px;
  align-items: center;
`;
export const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  accent-color: ${colors.chaletGreen};
  cursor: pointer;
`;
export const CheckboxLabel = styled.label`
  font: ${fonts.arial};
  color: ${colors.eclipse};
  font-size: 18px;
  margin: 0px 15px;
  width: 80%;
`;
