import { flow, types } from 'mobx-state-tree';
import { getPodcasts, getSinglePodcast, checkRssFeed, getSingleEpisode } from '../services/api';
import { composeError } from '../utils/transforms';
import { PodcastResponse, Category, TargetGroup, Episode } from '../types';
import { withRootStore } from './withRootStore';

const RssFeedModel = types.model('RssFeedModel', {
  title: types.string,
  image: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  publisher: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
});

const EpisodeModel = types.model('EpisodeModel', {
  id: types.number,
  name: types.string,
  author: types.string,
  image: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  src: types.maybeNull(types.string),
  order: types.number,
  notes: types.string,
  state: types.optional(types.string, ''),
});

const PodcastModel = types.model('PodcastModel', {
  id: types.optional(types.number, -1),
  name: types.string,
  image: types.maybeNull(types.string),
  description: types.string,
  releaseDate: types.maybeNull(types.string),
  author: types.string,
  published: types.optional(types.boolean, false),
  publisher: types.string,
  language: types.maybeNull(types.string),
  webUrl: types.maybeNull(types.string),
  rssFeed: types.maybeNull(types.string),
  order: types.maybeNull(types.number),
  mediaTypeId: types.optional(types.number, -1),
  episodes: types.optional(types.array(EpisodeModel), []),
  categoryIds: types.optional(types.array(types.number), []),
  targetGroupIds: types.optional(types.array(types.number), []),
});

export const PodcastStore = types
  .model({
    podcasts: types.optional(types.array(PodcastModel), []),
    selectedPodcast: types.maybeNull(PodcastModel),
    selectedEpisode: types.maybeNull(EpisodeModel),
    loading: false,
    rssFeed: types.maybeNull(RssFeedModel),
  })
  .extend(withRootStore)
  .actions((self) => {
    const getAllPodcasts = flow(function* () {
      try {
        const response = yield getPodcasts();
        self.podcasts = response.data.map((podcast: PodcastResponse) => ({
          ...podcast,
          // These are not used where getAllPodcasts is called
          // mediaTypeId: podcast.mediaType.id,
          // categoryIds: podcast.categories?.map((data: Category) => data.id),
          // targetGroupIds: podcast.targetGroups?.map((data: TargetGroup) => data.id),
        }));
      } catch (err) {
        const error = composeError(err);
        //self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedPodcast = flow(function* (id: string) {
      try {
        const response = yield getSinglePodcast(id);

        if (response.data.rssFeed && response.data.rssFeed != '') {
          self.selectedPodcast = {
            ...response.data,
            mediaTypeId: response.data.mediaType.id,
            categoryIds: response.data.categories?.map((data: Category) => data.id),
            targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
          };
        } else {
          self.selectedPodcast = {
            ...response.data,
            mediaTypeId: response.data.mediaType.id,
            episodes: response.data.episodes?.map((episode: Episode) => ({
              name: episode.name,
              src: episode.src,
              image: episode.image,
              releaseDate: episode.releaseDate,
              order: episode.order,
              notes: episode.notes,
              id: episode.id,
            })),
            categoryIds: response.data.categories?.map((data: Category) => data.id),
            targetGroupIds: response.data.targetGroups?.map((data: TargetGroup) => data.id),
          };

          // Sort episodes
          self.selectedPodcast?.episodes.sort((a, b) => a.order - b.order);
        }
      } catch (err) {
        const error = composeError(err);
        //self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const getSelectedEpisode = flow(function* (id: string) {
      try {
        const response = yield getSingleEpisode(id);
        self.selectedEpisode = {
          ...response.data,
          image: response.data.image ?? response.data.podcast.image,
          author: response.data.author ?? response.data.podcast.author,
        };
      } catch (err) {
        const error = composeError(err);
        //self.rootStore.errorStore.setError(error.message);
        console.error(err);
        console.error(error);
      }
    });

    const checkRssFeedUrl = flow(function* (url: string) {
      try {
        const response = yield checkRssFeed(url);
        self.rssFeed = {
          title: response.data.title,
          image: response.data.image?.url ?? response.data.itunes.image,
          description: response.data.description,
          language: response.data.language,
          publisher: response.data.itunes.owner.name,
        };
        //self.rootStore.errorStore.setSuccess('rss_feed_success');
      } catch (err) {
        console.error(err);
        const error = composeError(err);
        //self.rootStore.errorStore.setError(error.message);
      }
    });

    return {
      getAllPodcasts,
      getSelectedPodcast,
      checkRssFeedUrl,
      getSelectedEpisode,
    };
  });
