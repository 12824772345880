import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreProvider, rootStore, useMst } from '../../models/RootStore';
import { LayoutMain } from './LayoutMain';
import { StyledLayoutRoot } from './Layout.styled';

interface LayoutRootProps {
  className?: string;
  children: React.ReactNode;
}

export const LayoutRoot: React.FC<LayoutRootProps> = observer(({ children, className }) => {
  const {
    app: { fetchAppItems },
    userStore: { getMe, isLoggedIn, user },
  } = useMst();

  useEffect(() => {
    if (isLoggedIn && !user) getMe();
  }, [getMe, isLoggedIn, user]);

  useEffect(() => {
    fetchAppItems();
  }, [fetchAppItems]);

  return (
    <div>
      <StoreProvider value={rootStore}>
        <LayoutMain>
          <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
        </LayoutMain>
      </StoreProvider>
    </div>
  );
});
