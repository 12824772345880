import React, { FC, PropsWithChildren } from 'react';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

type SEOProps = {
  title?: string;
  description?: string;
  location?: {
    pathname?: string;
  };
  ogtitle?: string;
  ogdescription?: string;
  ogimage?: string;
};

export const Seo: FC<PropsWithChildren<SEOProps>> = ({
  title,
  description,
  location,
  ogtitle,
  ogdescription,
  ogimage,
  children,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultKeywords,
    author: defaultAuthor,
    image,
    siteUrl,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    author: defaultAuthor,
    image: image,
    url: `${siteUrl}${location?.pathname || ``}`,
    keywords: defaultKeywords,
    ogtitle: ogtitle || title,
    ogdescription: ogdescription || description,
    ogimage: ogimage || image,
  };

  return (
    <>
      <html lang="fi-FI" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords.join(', ')} />
      <meta name="author" content={seo.author} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.ogtitle} />
      <meta name="og:url" content={seo.url} />
      <meta name="og:description" content={seo.ogdescription} />
      <meta name="og:image" content={seo.ogimage} />
      {children}
    </>
  );
};
