import { flow, types, Instance, cast } from 'mobx-state-tree';
import { getAppItems, getLinks, getPromotions } from '../services/api';
import { PromotionResponse, NavigationLinkResponse, Category, NavigationLink } from '../types';
import { sortLink } from '../utils/sort';
import { writeStorage } from '../utils/storage';

const LinkModel = types.model('LinkModel', {
  id: types.optional(types.number, -1),
  text: types.maybeNull(types.string),
  url: types.optional(types.string, ''),
  placement: types.optional(types.number, -1),
  logo: types.maybeNull(types.string),
  isHeader: types.maybeNull(types.boolean),
  isNavigation: types.maybeNull(types.boolean),
  pageId: types.maybeNull(types.number),
  pageName: types.maybeNull(types.string),
  pageMediaType: types.maybeNull(types.number),
  pageCategories: types.maybeNull(types.array(types.string)),
});

export const AppModel = types.model('AppModel', {
  logo: types.maybeNull(types.string),
  appName: types.optional(types.string, ''),
  address: types.optional(types.string, ''),
  postalcode: types.optional(types.string, ''),
  city: types.optional(types.string, ''),
  phone: types.optional(types.string, ''),
  businessId: types.optional(types.string, ''),
  footerLinks: types.optional(types.array(LinkModel), []),
  state: types.optional(types.string, 'stagging'),
});

export const PromotionModel = types.model('PromotionModel', {
  id: types.number,
  imageSrc: types.string,
  url: types.string,
  priority: types.number,
  text: types.optional(types.string, ''),
});

export type LinkModelType = Instance<typeof LinkModel>;

export const AppStore = types
  .model({
    appItem: types.maybeNull(AppModel),
    navigationLinks: types.optional(types.array(LinkModel), []),
    headerLinks: types.optional(types.array(LinkModel), []),
    promotions: types.optional(types.array(PromotionModel), []),
    selectedNavigation: types.maybeNull(LinkModel),
    menuOpen: false,
    crumbs: types.maybeNull(types.array(types.string)),
  })
  .actions((self) => {
    const setMenuOpen = (open?: boolean) => {
      self.menuOpen = open ?? !self.menuOpen;
    };

    const setSelectedNavigation = (link: NavigationLink | null) => {
      self.selectedNavigation = link
        ? {
            ...link,
            pageCategories: link.pageCategories ? cast([...link.pageCategories]) : null,
          }
        : null;
    };

    const setCrumbs = (paths: string[]) => {
      const decoded = paths.map((path) => decodeURI(path));
      let tempPaths = ['/'];
      tempPaths = tempPaths.concat(decoded);

      writeStorage('CRUMBS', JSON.stringify(tempPaths));
      self.crumbs = cast(tempPaths);
    };

    const fetchAppItems = flow(function* () {
      try {
        const result = yield getAppItems();
        self.appItem = result.data;
      } catch (error) {
        console.error(error);
      }
    });

    /**
     * Fetch links and transform them into navigation and header links
     */
    const fetchLinks = flow(function* () {
      try {
        const result = yield getLinks();

        const navigationLinks: NavigationLinkResponse[] = result.data
          .filter((link: NavigationLinkResponse) => link.isNavigation)
          .sort(sortLink)
          .map((link: NavigationLinkResponse) => ({
            ...link,
            text: link.text,
            pageId: link.collectionPage?.id || null,
            pageName: link.collectionPage?.name || null,
            pageMediaType: link.collectionPage?.mediaType?.id || null,
            pageCategories: link.collectionPage?.categories?.map((cat: Category) => cat.name),
          }));

        const headerLinks: LinkModelType[] = result.data
          .filter((result: LinkModelType) => result.isHeader)
          .sort(sortLink);

        self.navigationLinks = cast(navigationLinks);
        self.headerLinks = cast(headerLinks);
      } catch (error) {
        console.error(error);
      }
    });

    const getMainPagePromotions = flow(function* () {
      try {
        const response = yield getPromotions();
        const promotions = response.data
          .filter((response: PromotionResponse) => response.placement !== 0)
          .sort((a: PromotionResponse, b: PromotionResponse) => a.placement - b.placement)
          .map((promotion: PromotionResponse, index: number) => ({
            id: promotion.id,
            imageSrc: promotion.image,
            url: promotion.url,
            priority: promotion.placement === index + 1 ? promotion.placement : index + 1,
            text: promotion.promotionText || '',
          }));

        self.promotions = promotions;
      } catch (error) {
        console.error(error);
      }
    });

    return {
      setMenuOpen,
      setSelectedNavigation,
      setCrumbs,
      fetchAppItems,
      fetchLinks,
      getMainPagePromotions,
    };
  });
