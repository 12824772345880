import { api, API_PATHS } from './api';
import { User, LoginResponse } from '../../types';

export const registerUser = async (userDetails: User) => {
  const res = await api.post(API_PATHS.auth.register, userDetails);
  return res.data;
};

export const userLogin = async (email: string, password: string): Promise<LoginResponse | undefined> => {
  const res = await api.post<LoginResponse>(API_PATHS.auth.login, { email, password }, {});
  return res.data;
};

export const userLogout = async () => {
  return api.get(API_PATHS.user.logout, {});
};

export const verifyUserEmail = async (token: string) => {
  const res = await api.post<LoginResponse>(API_PATHS.auth.verifyEmail, { token });
  return res.data;
};
