import React, { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { AppStateContextType, IAppState } from './appState';
import useContextWrapper from './useContextWrapper';

export const AppStateContext = createContext<AppStateContextType | null>(null);

export const useAppStateContext = () =>
  useContextWrapper(AppStateContext, { contextName: useAppStateContext.name, providerName: AppStateProvider.name });

export const AppStateProvider = ({ children }: PropsWithChildren) => {
  const [appState, setAppState] = useState<IAppState>({ title: '', showHeader: true });

  const value = useMemo(
    () => ({
      appState,
      setAppState,
    }),
    [appState]
  );

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};

export default AppStateProvider;
