import { Community } from '../../types';
import { api } from './api';

const API_PATHS = Object.freeze({
  community: {
    root: '/community',
    id: '/community/{id}',
  },
});

// Community
export const getCommunities = async (): Promise<Community[]> => {
  const retval = await api.get(API_PATHS.community.root, {});
  return retval.data;
};
