import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { ImageHeaderContainer, ImageHeaderText, Favorite } from './Common.styled';

interface ImageHeaderProps extends PropsWithChildren {
  showFavorite: boolean;
  backgroundColor?: string;
}

export const ImageHeader: React.FC<ImageHeaderProps> = observer(({ children, backgroundColor, showFavorite }) => {
  return (
    <ImageHeaderContainer>
      <ImageHeaderText>{children}</ImageHeaderText>
      {showFavorite && <Favorite backgroundColor={backgroundColor} />}
    </ImageHeaderContainer>
  );
});
