import { types, flow } from 'mobx-state-tree';
import { StoryTip } from '../types';
import { withRootStore } from './withRootStore';
import { NotificationType } from './NotificationStore';
import { onlineMagazineApi } from '../services/api';

export const OnlineMagazineStore = types
  .model({})
  .extend(withRootStore)
  .actions((self) => {
    const sendStoryTip = flow(function* (storyTip: StoryTip) {
      try {
        yield onlineMagazineApi.sendStoryTip(storyTip);

        self.rootStore.notificationStore.createNotification({
          message: 'Juttuvinkki on lähetetty',
          notificationType: NotificationType.Success,
        });
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    return {
      sendStoryTip,
    };
  });
