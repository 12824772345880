import { types, Instance, flow, cast } from 'mobx-state-tree';
import { fetchAllMagazines, fetchMagazineById, fetchFiltredMagazines } from '../services/api';
import { Category, Magazine, Role, Tag, MediaFilterParams, Article } from '../types';
import { MediaModel } from './MediaStore';

const PagesModel = types.model('PagesModel', {
  id: types.number,
  pageNumber: types.number,
  pageUrl: types.string,
});

const ArticleModelSimple = types.model('ArticleModelSimple', {
  id: types.optional(types.number, -1),
  title: types.optional(types.string, ''),
  lead: types.optional(types.string, ''),
  image: types.optional(types.string, ''),
  text: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
  priorityIndex: types.optional(types.number, -1),
  published: types.optional(types.boolean, false),
  releaseDate: types.optional(types.string, ''),
  author: types.optional(types.string, ''),
});

const MagazineModel = types.model('MagazineModel', {
  id: types.number,
  name: types.string,
  number: types.number,
  publisher: types.string,
  releaseDate: types.string,
  thumbnailUrl: types.string,
  description: types.string,
  categories: types.maybeNull(types.array(types.number)),
  tags: types.maybeNull(types.array(types.number)),
  userRole: types.maybeNull(types.array(types.number)),
  pages: types.array(PagesModel),
  articles: types.optional(types.array(ArticleModelSimple), []),
});

export type MagazineModel = Instance<typeof MagazineModel>;
export type ArticleModelSimple = Instance<typeof ArticleModelSimple>;
export type PagesModel = Instance<typeof PagesModel>;

export const MagazineStore = types
  .model({
    selectedMagazine: types.maybeNull(MagazineModel),
    magazines: types.optional(types.array(MediaModel), []),
    filteredMagazines: types.optional(types.array(MediaModel), []),
    pages: types.optional(types.array(PagesModel), []),
    showMagazine: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const getAllMagazines = flow(function* () {
      try {
        const response = yield fetchAllMagazines();
        const tempMagazines = response.data?.map((magazine: Magazine) => {
          return {
            id: magazine.id,
            imageSrc: magazine.thumbnailUrl,
            mediaSrc: `/lehdet?magazineId=${magazine.id}`,
            title: magazine.publisher,
            description: `${magazine.name} - Nro: ${magazine.number}`,
            publisher: magazine.publisher,

            releaseDate: magazine.releaseDate,
          };
        });

        self.magazines = tempMagazines;
      } catch (error) {
        console.error(error);
      }
    });

    const getMagazineById = flow(function* (id: string) {
      try {
        const response: { data: Magazine } = yield fetchMagazineById(id);
        const tempMagazine = {
          ...response.data,

          releaseDate: response.data.releaseDate,

          categories: response.data.categories?.map((data: Category) => data.id),
          tags: response.data.tags?.map((data: Tag) => data.id),
          userRole: response.data.userRoles?.map((data: Role) => data.id),
          pages: response.data.pages.sort((a, b) => a.pageNumber - b.pageNumber),
          articles: response.data.articles?.map((data: Article) => {
            return {
              ...data,
              publisher: data.publisher ?? '',
              releaseDate: data.releaseDate,

              author: data.author ?? '',
            };
          }),
        };
        self.selectedMagazine = cast(tempMagazine);
      } catch (error) {
        console.error(error);
      }
    });

    const getFilteredMagazines = flow(function* (params: MediaFilterParams) {
      try {
        const response = yield fetchFiltredMagazines(params);
        const data = response.data.map((magazine: Magazine) => ({
          ...magazine,
          publisher: magazine.publisher ?? '',
          releaseDate: magazine.releaseDate,
          author: magazine.publisher ?? '',
        }));
        self.filteredMagazines = data;
        return data;
      } catch (error) {
        console.error(error);
      }
    });

    const toggleShowMagazine = function () {
      self.showMagazine = !self.showMagazine;
    };

    return {
      getAllMagazines,
      getMagazineById,
      getFilteredMagazines,
      toggleShowMagazine,
    };
  });
