import { types, Instance, flow, cast } from 'mobx-state-tree';
import { getSingleArticlePage, getAllArticles, fetchFilteredArticles } from '../services/api';
import { Tag, Community, Article, ArticleResponse, MediaFilterParams } from '../types';
import { MediaModel } from './MediaStore';

const ArticleModel = types.model('ArticleModel', {
  id: types.optional(types.number, -1),
  title: types.optional(types.string, ''),
  lead: types.optional(types.string, ''),
  text: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
  priorityIndex: types.optional(types.number, -1),
  published: types.optional(types.boolean, false),
  releaseDate: types.optional(types.string, ''),
  author: types.optional(types.string, ''),
  tags: types.optional(types.array(types.number), []),
  communities: types.optional(types.array(types.number), []),
});

export type ArticleModelType = Instance<typeof ArticleModel>;

export const ArticleStore = types
  .model({
    selectedArticle: types.optional(ArticleModel, {}),
    articles: types.optional(types.array(MediaModel), []),
    filteredArticles: types.optional(types.array(MediaModel), []),
  })
  .actions((self) => {
    const getArticleById = flow(function* (pageId: string) {
      try {
        const response: { data: ArticleResponse } = yield getSingleArticlePage(pageId);
        const tempArticle = {
          ...response.data,
          publisher: response.data.publisher ?? '',
          releaseDate: response.data.releaseDate,
          author: response.data.author ?? '',
          tags: response.data.tags?.map((data: Tag) => data.id),
          communities: response.data.communities?.map((data: Community) => data.id),
        };
        self.selectedArticle = cast(tempArticle);
        return tempArticle;
      } catch (error) {
        console.error(error);
      }
    });

    const getArticles = flow(function* () {
      try {
        const response: { data: Article[] } = yield getAllArticles();
        const tempArticles = response.data?.map((article: Article) => {
          return {
            id: article.id,
            mediaSrc: `/artikkeli?articleId=${article.id}`,
            title: article.title,
            description: article.lead,
            publisher: article.publisher ?? '',
            releaseDate: article.releaseDate,
            author: article.author ?? '',
          };
        });
        self.articles = cast(tempArticles);
      } catch (error) {
        console.error(error);
      }
    });

    const getFilteredArticles = flow(function* (params: MediaFilterParams) {
      try {
        const response = yield fetchFilteredArticles(params);
        const data = response.data.map((article: Article) => ({
          ...article,
          publisher: article.publisher ?? '',
          releaseDate: article.releaseDate,
          author: article.author ?? '',
        }));
        self.filteredArticles = data;
        return data;
      } catch (error) {
        console.error(error);
      }
    });

    return {
      getArticleById,
      getArticles,
      getFilteredArticles,
    };
  });
