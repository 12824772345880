import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useSpring } from 'react-spring';
import { useMst } from '../../models/RootStore';
import { NotificationType } from '../../models/NotificationStore';
import { NotificationWrapper, CloseButton, Text, Wrapper } from './Notification.styled';
import { colors } from '../../theme/colors';
import Close from '../../resources/icons/close.svg';
import SvgIcon from '../Icons/SvgIcon';

export const Notification: React.FC = observer(() => {
  const {
    notificationStore: { notification, createNotification },
  } = useMst();
  const isSuccess = notification?.notificationType === NotificationType.Success;

  useEffect(() => {
    if (!notification) return;
    const timeoutId = setTimeout(
      () => {
        createNotification(null);
      },
      notification.notificationType == NotificationType.Error ? 8000 : 4000
    );
    return () => clearTimeout(timeoutId);
  }, [notification, createNotification]);

  const notificationStyle = useSpring({
    transform: notification ? 'scale(1)' : 'scale(0)',
    opacity: notification ? 1 : 0,
  });

  if (!notification) return null;
  return (
    <Wrapper>
      <NotificationWrapper
        style={{
          ...notificationStyle,
          backgroundColor: isSuccess ? colors.primaryGreen : colors.red,
        }}
      >
        <Text>{notification?.message}</Text>
        <CloseButton onClick={() => createNotification(null)}>
          <SvgIcon src={Close} fill={colors.white} width={15} height={15} />
        </CloseButton>
      </NotificationWrapper>
    </Wrapper>
  );
});
