import { Favorite } from '../../types/Favorite';
import { api } from './api';

const API_PATHS = Object.freeze({
  favorite: {
    root: '/favorite',
    delete: '/favorite',
  },
});

// Favorite
export const getAllFavorites = async (): Promise<Favorite[]> => {
  const result = await api.get(API_PATHS.favorite.root);
  return result.data;
};

export const storeSingleFavorite = async (favorite: Favorite): Promise<void> => {
  return await api.post(API_PATHS.favorite.root, favorite);
};

export const removeSingleFavorite = async (favorite: Favorite): Promise<void> => {
  return await api.delete(API_PATHS.favorite.delete, {
    data: favorite,
  });
};
