import { AxiosError } from 'axios';

const apiErrorHandler = (error: AxiosError) => {
  if (error.response) {
    const {
      response: { data },
    } = error;
    if (data.status && data.error) {
      throw {
        status: data.status,
        message: data.error,
      };
    }
    throw {
      ...error,
      message: 'Tapahtui virhe',
    };
  }
};

export default apiErrorHandler;
