import { types, Instance, flow, cast } from 'mobx-state-tree';
import {
  getLatestMedias,
  getLatestMediasByType,
  fetchMediatypeParams,
  fetchFilteredMedia,
  getUserRecommendedMedia,
  getGeneralRecommendation,
  saveRating,
  fetchMediaTypes,
} from '../services/api';

import { MediaFilterParams, MediaRating, LatestMedia, MediaFile } from '../types';
import { rootStore } from './RootStore';
import { withRootStore } from './withRootStore';

export enum MediaGrade {
  low = 0.33,
  average = 0.66,
  high = 0.99,
}

export const MediaModel = types.model('MediaModel', {
  id: types.number,
  imageSrc: types.string,
  mediaSrc: types.string,
  title: types.string,
  description: types.optional(types.string, ''),
  publisher: types.optional(types.string, ''),
  releaseDate: types.optional(types.string, ''),
  author: types.optional(types.string, ''),
});

export const MediaTypeParamsModel = types.model('MediaTypeParamsModel', {
  authors: types.optional(types.array(types.string), []),
  publishers: types.optional(types.array(types.string), []),
  releaseYears: types.optional(types.array(types.number), []),
});

export const MediaTypeModel = types.model('MediaTypeModel', {
  id: types.optional(types.number, -1),
  media: types.optional(types.string, ''),
  createdAt: types.optional(types.string, ''),
});

export const MediaTypeEnumModel = types.model('MediaTypeEnumModel', {
  Artikkelit: types.optional(types.number, -1),
  Musiikki: types.optional(types.number, -1),
  Lehdet: types.optional(types.number, -1),
  Äänikirjat: types.optional(types.number, -1),
  Uutiset: types.optional(types.number, -1),
  Videot: types.optional(types.number, -1),
  Blogit: types.optional(types.number, -1),
  Podcastit: types.optional(types.number, -1),
  Näköislehdet: types.optional(types.number, -1),
});

export type MediaModelType = Instance<typeof MediaModel>;
export type MediaTypeParamsType = Instance<typeof MediaTypeParamsModel>;
export type MediaTypeModelType = Instance<typeof MediaTypeModel>;
export type MediaTypeEnumModelType = Instance<typeof MediaTypeEnumModel>;

export const MediaStore = types
  .model({
    media: types.optional(types.array(MediaModel), []),
    mediatypeParams: types.optional(MediaTypeParamsModel, {}),
    latestMedia: types.optional(types.array(MediaModel), []),
    mediaTypes: types.optional(types.array(MediaTypeModel), []),
    selectedMediaType: types.maybeNull(MediaTypeModel),
  })
  .extend(withRootStore)
  .actions((self) => {
    /**
     * TODO
     * Fetch Medias for Front page categories
     */

    const getMediaTypes = flow(function* () {
      try {
        const response = yield fetchMediaTypes();

        self.mediaTypes = response.data;
      } catch (error) {
        console.error(error);
      }
    });

    const setSelectedMediaType = (pageMediaType?: number | null) => {
      const mediaTypeId = pageMediaType ?? rootStore.app.selectedNavigation?.pageMediaType;
      if (!mediaTypeId) return;
      const selectedMediaType = self.mediaTypes.find(({ id }) => id === mediaTypeId);
      self.selectedMediaType = { ...selectedMediaType } as MediaTypeModelType;
    };

    const getLatest = flow(function* () {
      try {
        const result: { data: LatestMedia } = yield getLatestMedias();
        return result.data;
      } catch (error) {
        console.error(error);
      }
    });

    // Replace getLatest function
    const getLatestMedia = flow(function* () {
      try {
        const response = yield getLatestMedias();
        self.latestMedia = response.data;
      } catch (error) {
        console.error(error);
      }
    });

    const getLatestByMediaType = flow(function* (pageId: number) {
      try {
        const result = yield getLatestMediasByType(pageId.toString());
        self.media = result.data;
      } catch (error) {
        console.error(error);
      }
    });

    const getMediaTypeParams = flow(function* (mediaType: number) {
      try {
        const response = yield fetchMediatypeParams(mediaType.toString());
        self.mediatypeParams = response.data;
      } catch (error) {
        console.error(error);
      }
    });

    const getMediaByParams = flow(function* (params: MediaFilterParams) {
      try {
        const response = yield fetchFilteredMedia(params);
        self.media = response.data;
        return response.data;
      } catch (error) {
        console.error(error);
      }
    });

    const getRecommendation = flow(function* () {
      try {
        const response: { data: MediaFile[] } = self.rootStore.userStore.token
          ? yield getUserRecommendedMedia()
          : yield getGeneralRecommendation();
        self.media = cast(response.data?.filter((el) => el !== null));
      } catch (error) {
        console.error(error);
      }
    });

    const saveMediaRating = flow(function* (rating: MediaRating) {
      try {
        if (self.rootStore.userStore.token) {
          yield saveRating(rating);
        }
      } catch (error) {
        console.error(error);
      }
    });

    return {
      getLatest,
      getLatestMedia,
      getLatestByMediaType,
      getMediaTypeParams,
      getMediaByParams,
      getRecommendation,
      saveMediaRating,
      getMediaTypes,
      setSelectedMediaType,
    };
  });
