import styled from 'styled-components';
import { animated } from 'react-spring';
import { Text as CommonText } from '../Common';
import { colors } from '../../theme/colors';
import { breakpoints } from '../../theme/variables';

export const Wrapper = styled(animated.div)`
  position: fixed;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
`;

export const NotificationWrapper = styled(animated.div)`
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  width: 90%;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    width: 350px;
  }
`;
export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  align-self: flex-start;
  cursor: pointer;
  padding: 0px;

  :hover {
    opacity: 0.8;
  }
`;
export const Text = styled(CommonText)`
  color: ${colors.white};
  font-size: 18px;
  padding: 5px 0px;
`;
