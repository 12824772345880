export class Settings {
  static readonly IdleTimeoutSeconds: number = 30 * 60;
  static readonly IdleTimeoutLogoutSeconds: number = 60;
}

export class Names {
  static readonly Publisher: string = 'Aikamedia';
  static readonly Prortal: string = 'Ajassa';
  static readonly RistinVoitto: string = 'Ristin Voitto';
}

export class MediaTypes {
  static readonly News: string = 'Uutiset';
  static readonly Newspapers: string = 'Lehdet';
  static readonly AudioBooks: string = 'Äänikirjat';
  static readonly PodCasts: string = 'Podcastit';
}

export class Data {
  static readonly SubscriperTargetGroupId: number = 4;
  static readonly AjassaMagazineId: number = 1;
}

export default {
  Settings,
  Names,
  MediaTypes,
  Data,
};
