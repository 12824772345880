/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { types, flow, Instance } from 'mobx-state-tree';
import { navigate } from 'gatsby';
import { authApi, getUserSubscriptions, userApi } from '../services/api';
import { LoginResponse, User, UserUpdateRequest } from '../types';
import { readStorage, writeStorage, deleteStorage, AUTH_TOKEN, USER_LOCAL } from '../utils/storage';
import { NotificationType } from './NotificationStore';
import { withRootStore } from './withRootStore';
import { checkUserType } from '../utils/checkUserType';
import { Subscription } from '../types/Subscription';
import { AxiosResponse } from 'axios';

export enum AuthModalPhase {
  None = 'None',
  VerifyEmail = 'VerifyEmail',
}

const SubscriptionModel = types.model('SubscriptionModel', {
  id: types.optional(types.string, ''),
  status: types.string,
  subscriptionTypeId: types.maybeNull(types.number), // 1 -> Stipe, 2 -> Subscription Code, 3... -> types defined in subscription type entity
  subscriptionCode: types.optional(types.string, ''),
  currentPeriodEnd: types.Date,
  currentPeriodStart: types.Date,
  interval: types.string,
  amount: types.number,
  subscriptionId: types.optional(types.string, ''),
  priceId: types.optional(types.string, ''),
  productId: types.optional(types.string, ''),
  ownerId: types.maybeNull(types.number), // perhe tilauksen tai paritilauksen omistaja id
  //parent: types.maybeNull(SubscriptionModel),
  //parentSubscriptions:  types.optional(types.array(SubscriptionModel), []),
});

export const UserModel = types.model('UserModel', {
  email: types.string,
  firstname: types.optional(types.string, ''),
  lastname: types.optional(types.string, ''),
  address: types.optional(types.string, ''),
  postalcode: types.optional(types.string, ''),
  city: types.optional(types.string, ''),
  country: types.optional(types.string, ''),
  phone: types.optional(types.string, ''),
  isEmployee: types.optional(types.boolean, false),
  marketing: types.optional(types.boolean, false),
  newsletter: types.optional(types.boolean, false),
  empNewsletter: types.optional(types.boolean, false),
  permission: types.optional(types.boolean, false),
  subscriber: types.optional(types.boolean, false),
  customerId: types.maybeNull(types.string),
  ageGroup: types.maybeNull(types.number),
  gender: types.maybeNull(types.number),
});

export type UserModelType = Instance<typeof UserModel>;
export type SubscriptionModelType = Instance<typeof SubscriptionModel>;

export const UserStore = types
  .model({
    user: types.maybeNull(UserModel),
    token: types.optional(types.maybeNull(types.string), readStorage('AUTH_TOKEN')),
    localUser: types.optional(types.maybeNull(types.string), readStorage('USER_LOCAL')),
    verifySuccess: types.maybeNull(types.boolean),
    verifyFetching: false,
    subscriptions: types.array(SubscriptionModel),
  })
  .extend(withRootStore)
  .views((self) => ({
    get isLoggedIn() {
      return !!self.token;
    },
  }))
  .actions((self) => {
    const register = flow(function* (user: User) {
      try {
        const response: LoginResponse | undefined = yield authApi.registerUser(user);
        if (response) {
          setTokenAndUser(response);

          fillUserSubscriptions();
        }
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const fillUserSubscriptions = flow(function* () {
      const subscriptions: AxiosResponse<Subscription[]> = yield getUserSubscriptions();

      subscriptions?.data?.forEach((item: Subscription) => {
        self.subscriptions.push({
          id: item.id,
          status: item.status,
          subscriptionTypeId: item.subscriptionTypeId,
          subscriptionCode: item.subscriptionCode,
          currentPeriodEnd: new Date(item.currentPeriodEnd),
          currentPeriodStart: new Date(item.currentPeriodStart),
          interval: item.interval,
          amount: item.amount,
          subscriptionId: item.subscriptionId,
          priceId: item.priceId,
          productId: item.productId,
          ownerId: item.ownerId,
        });
      });
    });

    const setTokenAndUser = (response: LoginResponse) => {
      self.token = response.token;
      self.user = {
        email: response.user.email,
        firstname: response.user.firstname ?? '',
        lastname: response.user.lastname ?? '',
        address: response.user.address ?? '',
        postalcode: response.user.postalcode ?? '',
        city: response.user.city ?? '',
        country: response.user.country ?? '',
        phone: response.user.phone ?? '',
        isEmployee: checkUserType(response.user),
        marketing: response.user.marketing ?? false,
        newsletter: response.user.newsletter ?? false,
        empNewsletter: response.user.empNewsletter ?? false,
        permission: response.user.permission ?? false,
        subscriber: response.user.subscriber ?? false,
        customerId: response.user.customerId ?? '',
        ageGroup: response.user.ageGroup ?? null,
        gender: response.user.gender ?? null,
      };

      writeStorage(USER_LOCAL, JSON.stringify(response.user));
      writeStorage(AUTH_TOKEN, response.token);
    };

    const login = flow(function* (email: string, password: string) {
      try {
        const response: LoginResponse | undefined = yield authApi.userLogin(email, password);
        if (response?.token) {
          setTokenAndUser(response);

          fillUserSubscriptions();

          navigate(-1);
        } else {
          self.rootStore.notificationStore.createNotification({
            message: 'Kirjautumisessa tapahtui virhe',
            notificationType: NotificationType.Error,
          });
        }
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const logout = flow(function* () {
      try {
        yield authApi.userLogout();
        self.rootStore.mediaStore.getRecommendation();
        self.user = null;
        self.token = null;
        deleteStorage(AUTH_TOKEN);
        deleteStorage(USER_LOCAL);
        navigate('/');
      } catch (err: any) {
        console.error(err);
      }
    });

    const getMe = flow(function* () {
      try {
        const response = yield userApi.fetchMe();

        self.user = {
          email: response.data.email,
          firstname: response.data.firstname ?? '',
          lastname: response.data.lastname ?? '',
          address: response.data.address ?? '',
          postalcode: response.data.postalcode ?? '',
          city: response.data.city ?? '',
          country: response.data.country ?? '',
          phone: response.data.phone ?? '',
          isEmployee: checkUserType(response.data),
          marketing: response.data.marketing ?? false,
          newsletter: response.data.newsletter ?? false,
          empNewsletter: response.data.empNewsletter ?? false,
          permission: response.data.permission ?? false,
          subscriber: response.data.subscriber ?? false,
          customerId: response.data.customerId ?? '',
          ageGroup: response.data.ageGroup ?? null,
          gender: response.data.gender ?? null,
        };

        fillUserSubscriptions();
      } catch (err: any) {
        console.error(err);
        self.user = null;
        self.token = null;
        deleteStorage(USER_LOCAL);
        deleteStorage(AUTH_TOKEN);
      }
    });

    const verifyEmail = flow(function* (token: string) {
      self.verifyFetching = true;
      try {
        const response: LoginResponse | undefined = yield authApi.verifyUserEmail(token);
        if (response?.token) {
          self.token = response.token;
          self.user = {
            email: response.user.email,
            firstname: response.user.firstname ?? '',
            lastname: response.user.lastname ?? '',
            address: response.user.address ?? '',
            postalcode: response.user.postalcode ?? '',
            city: response.user.city ?? '',
            country: response.user.country ?? '',
            phone: response.user.phone ?? '',
            isEmployee: false,
            marketing: response.user.marketing ?? false,
            newsletter: response.user.newsletter ?? false,
            empNewsletter: response.user.empNewsletter ?? false,
            permission: response.user.permission ?? false,
            subscriber: response.user.subscriber ?? false,
            customerId: response.user.customerId ?? '',
            ageGroup: response.user.ageGroup ?? null,
            gender: response.user.gender ?? null,
          };
          self.verifySuccess = true;
          writeStorage(AUTH_TOKEN, response.token);

          fillUserSubscriptions();
        } else {
          console.error(response);
          self.verifySuccess = false;
          self.rootStore.notificationStore.createNotification({
            message: 'Sähköpostin varmentamisessa tapahtui virhe',
            notificationType: NotificationType.Error,
          });
        }
      } catch (err: any) {
        console.error(err);
        self.verifySuccess = false;
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      } finally {
        self.verifyFetching = false;
      }
    });

    const updateUserDetails = flow(function* (user: UserUpdateRequest) {
      try {
        yield userApi.updateUser(user);
        yield getMe();

        self.rootStore.notificationStore.createNotification({
          message: 'Tietosi on onnistuneesti päivitetty.',
          notificationType: NotificationType.Success,
        });
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const forgotPassword = flow(function* (email: string) {
      try {
        yield userApi.forgotPassword(email);

        self.rootStore.notificationStore.createNotification({
          message: 'Salasanan palautuslinkki on lähetetty sähköpostiisi.',
          notificationType: NotificationType.Success,
        });
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const resetPassword = flow(function* (token: string, newPassport: string) {
      try {
        yield userApi.resetPassword(token, newPassport);

        self.rootStore.notificationStore.createNotification({
          message: 'Salasanan on onnistuneesti vaihdettu.',
          notificationType: NotificationType.Success,
        });
        navigate('/');
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const subscribeNewsletter = flow(function* (email: string, marketing: boolean) {
      try {
        yield userApi.subscribeNewsletter(email, marketing);

        self.rootStore.notificationStore.createNotification({
          message: 'Uutiskirje tilattu.',
          notificationType: NotificationType.Success,
        });
        navigate('/');
      } catch (err: any) {
        console.error(err);
        self.rootStore.notificationStore.createNotification({
          message: err.message,
          notificationType: NotificationType.Error,
        });
      }
    });

    const checkLogin = function () {
      if (self.token == '') {
        self.token = readStorage(AUTH_TOKEN);
      }
    };

    return {
      register,
      login,
      logout,
      getMe,
      verifyEmail,
      updateUserDetails,
      forgotPassword,
      resetPassword,
      subscribeNewsletter,
      checkLogin,
    };
  });
