import React, { FC } from 'react';
import { navigate } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { useMst } from '../../models/RootStore';
import { breakpoints } from '../../theme/variables';
import { Container, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ArrowRight from '../../resources/icons/arrow_white_app.svg';
import SvgIcon from '../Icons/SvgIcon';
import { colors } from '../../theme/colors';
import { Community } from '../../types';

interface OnlineMagazineMenuProps {
  open: boolean;
  handleClose: () => void;
  anchor: Element | null;
  communities: Community[] | undefined;
}

const OnlineMagazineMenu: FC<OnlineMagazineMenuProps> = ({
  open,
  handleClose,
  anchor,
  communities,
}: OnlineMagazineMenuProps) => {
  const { t } = useTranslation();
  const {
    userStore: { token, logout },
  } = useMst();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.lg });

  const sections = [
    'News',
    'LifeSkill',
    'SpiritualGrowth',
    'Congregation',
    'People',
    'Phenomena',
    'Internationality',
    'Culture',
    'Nostalgia',
    'Kids',
    'Editorials',
    'Columns',
    'Opinions',
    'PointOfViews',
    'GuestPens',
  ];

  return (
    <Menu
      anchorEl={anchor}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      marginThreshold={0}
      slotProps={{
        root: {
          style: {
            top: 20,
            marginRight: 17,
          },
        },
        paper: {
          style: {
            backgroundColor: 'black',
            color: 'white',
            width: '100%',
            maxWidth: '100%',
            left: 0,
            right: 0,
            borderRadius: 0,
          },
        },
      }}
      // disableScrollLock={true}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Container maxWidth={isMobile ? 'sm' : 'md'}>
        <Grid container mt={2} mb={2}>
          <Grid container item xs={12} md={4} mb={4} style={{ borderRight: '1px solid white' }}>
            <Typography className="menuTitle">{t('menu.sections')}</Typography>
            {sections.map((section) => (
              <Grid container item xs={12} key={section} mb={0.5}>
                <Grid item>
                  <MenuItem
                    className="menuItem"
                    onClick={() => {
                      handleClose();
                      navigate(`/verkkolehti/osasto?magazineId=1&sectionList=${section}`);
                    }}
                  >
                    {t(`articleSections.${section}`)}
                  </MenuItem>
                </Grid>
                <Grid item ml={1}>
                  <SvgIcon src={ArrowRight} fill={colors.white} width={8} height={8} />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            mb={4}
            pl={4}
            style={{ borderRight: '1px solid white' }}
            alignContent={'flex-start'}
          >
            <Grid item xs={12} mb={4}>
              <Typography className="menuTitle">{t('menu.byMagazine')}</Typography>
              {communities?.map((community) => (
                <Grid item xs={12} key={community.id} mb={0.5}>
                  <MenuItem
                    className="menuItem"
                    onClick={() => {
                      handleClose();
                      navigate(`/verkkolehti?communityList=${community.id}`);
                    }}
                  >
                    {community.community}
                  </MenuItem>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12} mb={0.5}>
              <Typography className="menuTitle">{t('menu.ristinvoitto')}</Typography>
              <Grid item xs={12} mb={0.5}>
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    window.open('https://www.ristinvoitto.fi/nakoislehdet/', '_blank');
                  }}
                >
                  {t('menu.archive')}
                </MenuItem>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={4} pl={4} alignContent={'flex-start'}>
            <Grid item xs={12} mb={4}>
              <Typography className="menuTitle">{t('menu.info')}</Typography>
              <Grid item xs={12} mb={0.5}>
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/verkkolehti/yhteystiedot');
                  }}
                >
                  {t('menu.contactinformation')}
                </MenuItem>
              </Grid>
              <Grid item xs={12} mb={0.5}>
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/verkkolehti/juttuvinkki');
                  }}
                >
                  {t('menu.storyTip')}
                </MenuItem>
              </Grid>
              {/* <Grid item xs={12} mb={0.5}>
              <MenuItem
                className="menuItem"
                onClick={() => {
                  handleClose();
                  navigate('/verkkolehti/toimitus');
                }}
              >
                {t('menu.staff')}
              </MenuItem>
            </Grid> */}
            </Grid>
            <Grid item xs={12} mb={4}>
              <Typography className="menuTitle">{t('menu.profile')}</Typography>

              {!token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/salasana-unohtunut');
                  }}
                >
                  {t('login.forgot_password')}
                </MenuItem>
              )}
              {!token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/kirjaudu');
                  }}
                >
                  {t('menu.login')}
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/verkkolehti/suosikit?magazineId=1');
                  }}
                >
                  {t('menu.favorites')}
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/tilaukset');
                  }}
                >
                  {t('menu.my_subscriptions')}
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    navigate('/profiili');
                  }}
                >
                  {t('menu.my_information')}
                </MenuItem>
              )}
              {token && (
                <MenuItem
                  className="menuItem"
                  onClick={() => {
                    handleClose();
                    logout();
                  }}
                >
                  {t('menu.logout')}
                </MenuItem>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Menu>
  );
};

export default OnlineMagazineMenu;
