exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aanikirjat-tsx": () => import("./../../../src/pages/aanikirjat.tsx" /* webpackChunkName: "component---src-pages-aanikirjat-tsx" */),
  "component---src-pages-applink-tsx": () => import("./../../../src/pages/applink.tsx" /* webpackChunkName: "component---src-pages-applink-tsx" */),
  "component---src-pages-artikkeli-tsx": () => import("./../../../src/pages/artikkeli.tsx" /* webpackChunkName: "component---src-pages-artikkeli-tsx" */),
  "component---src-pages-hinnasto-tsx": () => import("./../../../src/pages/hinnasto.tsx" /* webpackChunkName: "component---src-pages-hinnasto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kategoriat-tsx": () => import("./../../../src/pages/kategoriat.tsx" /* webpackChunkName: "component---src-pages-kategoriat-tsx" */),
  "component---src-pages-kayttoehdot-tsx": () => import("./../../../src/pages/kayttoehdot.tsx" /* webpackChunkName: "component---src-pages-kayttoehdot-tsx" */),
  "component---src-pages-kirjaudu-tsx": () => import("./../../../src/pages/kirjaudu.tsx" /* webpackChunkName: "component---src-pages-kirjaudu-tsx" */),
  "component---src-pages-koontisivu-tsx": () => import("./../../../src/pages/koontisivu.tsx" /* webpackChunkName: "component---src-pages-koontisivu-tsx" */),
  "component---src-pages-lahja-maksettu-tsx": () => import("./../../../src/pages/lahja_maksettu.tsx" /* webpackChunkName: "component---src-pages-lahja-maksettu-tsx" */),
  "component---src-pages-lahja-maksu-tsx": () => import("./../../../src/pages/lahja_maksu.tsx" /* webpackChunkName: "component---src-pages-lahja-maksu-tsx" */),
  "component---src-pages-lahja-ostoskori-tsx": () => import("./../../../src/pages/lahja_ostoskori.tsx" /* webpackChunkName: "component---src-pages-lahja-ostoskori-tsx" */),
  "component---src-pages-lehdet-tsx": () => import("./../../../src/pages/lehdet.tsx" /* webpackChunkName: "component---src-pages-lehdet-tsx" */),
  "component---src-pages-listasivu-tsx": () => import("./../../../src/pages/listasivu.tsx" /* webpackChunkName: "component---src-pages-listasivu-tsx" */),
  "component---src-pages-maksettu-tsx": () => import("./../../../src/pages/maksettu.tsx" /* webpackChunkName: "component---src-pages-maksettu-tsx" */),
  "component---src-pages-maksu-tsx": () => import("./../../../src/pages/maksu.tsx" /* webpackChunkName: "component---src-pages-maksu-tsx" */),
  "component---src-pages-ostoskori-tsx": () => import("./../../../src/pages/ostoskori.tsx" /* webpackChunkName: "component---src-pages-ostoskori-tsx" */),
  "component---src-pages-palauta-salasana-tsx": () => import("./../../../src/pages/palauta-salasana.tsx" /* webpackChunkName: "component---src-pages-palauta-salasana-tsx" */),
  "component---src-pages-peruttu-tsx": () => import("./../../../src/pages/peruttu.tsx" /* webpackChunkName: "component---src-pages-peruttu-tsx" */),
  "component---src-pages-peruutus-tsx": () => import("./../../../src/pages/peruutus.tsx" /* webpackChunkName: "component---src-pages-peruutus-tsx" */),
  "component---src-pages-profiili-tsx": () => import("./../../../src/pages/profiili.tsx" /* webpackChunkName: "component---src-pages-profiili-tsx" */),
  "component---src-pages-rekisteroidy-tsx": () => import("./../../../src/pages/rekisteroidy.tsx" /* webpackChunkName: "component---src-pages-rekisteroidy-tsx" */),
  "component---src-pages-rekisteroity-tsx": () => import("./../../../src/pages/rekisteroity.tsx" /* webpackChunkName: "component---src-pages-rekisteroity-tsx" */),
  "component---src-pages-salasana-unohtunut-tsx": () => import("./../../../src/pages/salasana-unohtunut.tsx" /* webpackChunkName: "component---src-pages-salasana-unohtunut-tsx" */),
  "component---src-pages-sisaltosivu-tsx": () => import("./../../../src/pages/sisaltosivu.tsx" /* webpackChunkName: "component---src-pages-sisaltosivu-tsx" */),
  "component---src-pages-tietosuoja-tsx": () => import("./../../../src/pages/tietosuoja.tsx" /* webpackChunkName: "component---src-pages-tietosuoja-tsx" */),
  "component---src-pages-tilaukset-tsx": () => import("./../../../src/pages/tilaukset.tsx" /* webpackChunkName: "component---src-pages-tilaukset-tsx" */),
  "component---src-pages-uutiskirje-tsx": () => import("./../../../src/pages/uutiskirje.tsx" /* webpackChunkName: "component---src-pages-uutiskirje-tsx" */),
  "component---src-pages-varmistus-tsx": () => import("./../../../src/pages/varmistus.tsx" /* webpackChunkName: "component---src-pages-varmistus-tsx" */),
  "component---src-pages-verkkolehti-index-tsx": () => import("./../../../src/pages/verkkolehti/index.tsx" /* webpackChunkName: "component---src-pages-verkkolehti-index-tsx" */),
  "component---src-pages-verkkolehti-juttuvinkki-tsx": () => import("./../../../src/pages/verkkolehti/juttuvinkki.tsx" /* webpackChunkName: "component---src-pages-verkkolehti-juttuvinkki-tsx" */),
  "component---src-pages-verkkolehti-osasto-tsx": () => import("./../../../src/pages/verkkolehti/osasto.tsx" /* webpackChunkName: "component---src-pages-verkkolehti-osasto-tsx" */),
  "component---src-pages-verkkolehti-suosikit-tsx": () => import("./../../../src/pages/verkkolehti/suosikit.tsx" /* webpackChunkName: "component---src-pages-verkkolehti-suosikit-tsx" */),
  "component---src-pages-verkkolehti-yhteystiedot-tsx": () => import("./../../../src/pages/verkkolehti/yhteystiedot.tsx" /* webpackChunkName: "component---src-pages-verkkolehti-yhteystiedot-tsx" */),
  "component---src-pages-yhteystiedot-tsx": () => import("./../../../src/pages/yhteystiedot.tsx" /* webpackChunkName: "component---src-pages-yhteystiedot-tsx" */)
}

