import { types, Instance, flow } from 'mobx-state-tree';
import constants from '../config/constants';
import {
  getCollectionPageByName,
  getCollectionPageCategories,
  getAudiobookCollectionPage,
  getMagazineCollectionPage,
} from '../services/api';
import { MediaModel } from './MediaStore';
import { rootStore } from './RootStore';

const CategoryModel = types.model('CategoryModel', {
  id: types.number,
  title: types.string,
  mediaType: types.number,
  mediaFiles: types.optional(types.array(MediaModel), []),
});

export type CategoryModelType = Instance<typeof CategoryModel>;

export const CategoryStore = types
  .model({
    categories: types.optional(types.array(CategoryModel), []),
  })
  .actions((self) => {
    /**
     * TODO
     * Fetch Medias for Front page categories
     * Fetch Categories and category medias for page
     */
    const getCategoriesForPage = flow(function* (pageId: number) {
      try {
        const result = yield getCollectionPageByName(pageId.toString());

        self.categories = result.data.map((result: CategoryModelType) => ({
          ...result,
          mediaFiles: result.mediaFiles,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const getCategoriesForAudiobooks = flow(function* (pageId: number) {
      try {
        const result = yield getAudiobookCollectionPage(pageId.toString());

        self.categories = result.data.map((result: CategoryModelType) => ({
          ...result,
          mediaFiles: result.mediaFiles,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const getCategoriesForMagazines = flow(function* (pageId: number) {
      try {
        const result = yield getMagazineCollectionPage(pageId.toString());

        self.categories = result.data.map((result: CategoryModelType) => ({
          ...result,
          mediaFiles: result.mediaFiles,
        }));
      } catch (error) {
        console.error(error);
      }
    });

    const getCategoriesForMediatype = flow(function* (mediatype: number) {
      try {
        const selectedMediaType = rootStore.mediaStore.mediaTypes.find((el) => el.id === mediatype);
        const result = yield getCollectionPageCategories(mediatype.toString());
        self.categories = result.data.map((result: CategoryModelType) => ({
          ...result,
          mediaType: 1,
          mediaFiles: result.mediaFiles.map((file: any) => ({
            id: file.id,
            releaseDate: file.releaseDate || '',
            author: file.author || '',
            imageSrc: selectedMediaType?.media === constants.MediaTypes.Newspapers ? file.thumbnailUrl : file.image,
            mediaSrc:
              selectedMediaType?.media === constants.MediaTypes.Newspapers
                ? `/lehdet?magazineId=${file.id}`
                : `/artikkeli?articleId=${file.id}`,
            title: selectedMediaType?.media === constants.MediaTypes.Newspapers ? file.publisher : file.title,
            description:
              selectedMediaType?.media === constants.MediaTypes.Newspapers
                ? `${file.name} - Nro: ${file.number}`
                : file.lead,
            publisher: file.publisher ?? '',
          })),
        }));
      } catch (error) {
        console.error(error);
      }
    });

    return {
      getCategoriesForPage,
      getCategoriesForMediatype,
      getCategoriesForAudiobooks,
      getCategoriesForMagazines,
    };
  });
