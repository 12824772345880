import React, { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { Text as StyledText } from './Common.styled';

interface TextProps extends PropsWithChildren {
  className?: string;
}

export const Text: React.FC<TextProps> = observer(({ children, className }) => {
  return <StyledText className={className}>{children}</StyledText>;
});
